import React, { useState, useEffect } from "react";
import {
  ThemeProvider,
  makeStyles,
  createMuiTheme,
} from "@material-ui/core/styles";
import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import amber from "@material-ui/core/colors/amber";
import axios from "axios";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { apiUrl } from "../../utils/url";

const theme = createMuiTheme({
  palette: {
    primary: amber,
    secondary: {
      main: "#3F3D56",
    },
  },
  typography: {
    fontFamily: "'Work Sans', sans-serif",
    htmlFontSize: 10,
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  multiline: {
    width: "100%",
    margin: "0 auto",
    marginBottom: "3rem",
  },
  button: {
    color: "#fff",
    fontSize: "1.5rem",
    padding: "0.5rem 0",
    marginTop: "0.3rem",
    borderRadius: "6rem",
    width: "120px",
  },
  textField: {
    height: "4rem",
  },
}));

// constants for form options
const PUBLISHED_OPTIONS = [
  "Wrote a book, did not publish it",
  "Wrote a book, published it",
  "No",
];

const USED_ESTIMATOR_OPTIONS = ["Yes", "No"];

const BOOK_TYPE_OPTIONS = [
  "Children & Young Adult",
  "Adult Novel - Fiction",
  "Adult Novel - Nonfiction",
  "Short Story",
  "Family History, Legacy, Biography, Memoir",
  "Autobiography",
  "Business, Self Help, Workbook",
  "Religion & Spirituality",
  "Special Interest i.e. Recipe, Wine, Poetry, Sports",
];

// const REASON_OPTIONS = [
//   "Personal passion, for fun",
//   "For business",
//   "Family legacy, bio, memoir",
//   "To publish and sell",
// ];

const FOUNDBY_OPTIONS = [
  "Google Search",
  "Social / Digital Media Posting",
  "Social / Digital Media Ad",
  "Word-of-Mouth",
  "A Creative Connex Client",
  "A Creative Connex Professional",
];

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().trim().max(50, "Max 50 characters.").required("Required."),
  email: Yup.string().email("Must be a valid email.").required("Required."),
  phone: Yup.string()
    .required("Phone Required")
    .trim()
    .min(4, "Minimum 4 characters.")
    .max(20, "Max 20 characters"),
  city: Yup.string().trim().max(50, "Max 50 characters.").required("Required."),
  province: Yup.string()
    .trim()
    .max(50, "Max 50 characters.")
    .required("Required."),
  country: Yup.string()
    .trim()
    .max(50, "Max 50 characters.")
    .required("Required."),
  published: Yup.string().trim().max(50, "Max 50 characters."),
  usedEstimator: Yup.string().trim().max(50, "Max 50 characters."),
  bookType: Yup.string().trim().max(50, "Max 50 characters."),
  services: Yup.array(),
  foundBy: Yup.string().trim().max(50, "Max 50 characters."),
});
const INITIAL_VALUES = {
  name: "",
  email: "",
  phone: "",
  city: "",
  province: "",
  country: "",
  published: "",
  usedEstimator: "",
  bookType: "",
  services: [""],
  foundBy: "",
};

export default function ContactForm() {
  const classes = useStyles();

  const [products, setProducts] = useState([{ name: "" }]);
  const [published, setPublished] = useState(PUBLISHED_OPTIONS[0]);
  const [usedEstimator, setUsedEstimator] = useState(USED_ESTIMATOR_OPTIONS[0]);
  const [bookType, setBookType] = useState(BOOK_TYPE_OPTIONS[0]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // fetch product data
    axios.get(`${apiUrl}/products?type=standard`).then((response) => {
      setProducts(response.data);
    });
  }, []);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  // change handler for published dropdown
  const handlePublishedChange = (event) => {
    const newPublished = event.target.value;
    setPublished(newPublished);
  };

  // change handler for usedEstimator dropdown
  const handleUsedEstimatorChange = (event) => {
    const newUsedEstimator = event.target.value;
    setUsedEstimator(newUsedEstimator);
  };

  // change handler for bookType dropdown
  const handleBookTypeChange = (event) => {
    const newBookType = event.target.value;
    setBookType(newBookType);
  };

  // function for submitting lead
  const handleSubmitLead = async (values) => {
    const url = `${apiUrl}/leads`;
    const payload = values;
    const response = await axios.post(url, payload);
    return response.data;
  };

  return (
    <Formik
      onSubmit={async (values, actions) => {
        try {
          const valuesForAPI = {
            ...values,
            published,
            usedEstimator,
            bookType,
            services: selectedServices,
          };
          const data = await handleSubmitLead(valuesForAPI);
          actions.resetForm();
          setPublished(PUBLISHED_OPTIONS[0]);
          setUsedEstimator(USED_ESTIMATOR_OPTIONS[0]);
          setBookType(BOOK_TYPE_OPTIONS[0]);
          handleOpenDialog();
        } catch (e) {}
      }}
      validationSchema={VALIDATION_SCHEMA}
      initialValues={INITIAL_VALUES}
    >
      {({ values, errors, handleChange, setFieldValue }) => (
        <Form className={classes.root}>
          <ThemeProvider theme={theme}>
            <Grid container spacing={6}>
              {/* Name field */}
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.textField}
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  label="Name*"
                  variant="outlined"
                  color="secondary"
                  error={!!errors.name}
                  helperText={errors.name}
                  fullWidth
                />
              </Grid>

              {/* Email name field */}
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.textField}
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  label="Email*"
                  variant="outlined"
                  color="secondary"
                  error={!!errors.email}
                  helperText={errors.email}
                  fullWidth
                />
              </Grid>

              {/* Phone field */}
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.textField}
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  label="Phone Number*"
                  variant="outlined"
                  color="secondary"
                  error={!!errors.phone}
                  helperText={errors.phone}
                  fullWidth
                />
              </Grid>

              {/* City field */}
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.textField}
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  label="City*"
                  variant="outlined"
                  color="secondary"
                  error={!!errors.city}
                  helperText={errors.city}
                  fullWidth
                />
              </Grid>

              {/* Region field */}
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.textField}
                  name="province"
                  value={values.province}
                  onChange={handleChange}
                  label="Province/State/Region"
                  variant="outlined"
                  color="secondary"
                  error={!!errors.province}
                  helperText={errors.province}
                  fullWidth
                />
              </Grid>

              {/* Country field */}
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.textField}
                  name="country"
                  value={values.country}
                  onChange={handleChange}
                  label="Country*"
                  variant="outlined"
                  color="secondary"
                  error={!!errors.country}
                  helperText={errors.country}
                  fullWidth
                />
              </Grid>

              {/* Published field */}
              <Grid item xs={12}>
                <h1 className="contact-form__heading">
                  Have you already published a book in the past?
                </h1>
                <FormControl style={{ width: "100%" }}>
                  <Select
                    id="published"
                    className={classes.select}
                    value={published}
                    onChange={handlePublishedChange}
                    variant="filled"
                    fullWidth
                  >
                    {PUBLISHED_OPTIONS.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                    ;
                  </Select>
                </FormControl>
              </Grid>

              {/* UsedEstimator field */}
              <Grid item xs={12}>
                <h1 className="contact-form__heading">
                  Did you use our “Book $ Estimator” to get an approx. book
                  development cost?
                </h1>
                <FormControl style={{ width: "100%" }}>
                  <Select
                    id="usedEstimator"
                    className={classes.select}
                    value={usedEstimator}
                    onChange={handleUsedEstimatorChange}
                    variant="filled"
                    fullWidth
                  >
                    {USED_ESTIMATOR_OPTIONS.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                    ;
                  </Select>
                </FormControl>
              </Grid>

              {/* BookType field */}
              <Grid item xs={12}>
                <h1 className="contact-form__heading">
                  What type of book are you wanting to write?
                </h1>
                <FormControl style={{ width: "100%" }}>
                  <Select
                    id="bookType"
                    className={classes.select}
                    value={bookType}
                    onChange={handleBookTypeChange}
                    variant="filled"
                    fullWidth
                  >
                    {BOOK_TYPE_OPTIONS.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                    ;
                  </Select>
                </FormControl>
              </Grid>

              {/* Services field */}
              <Grid item xs={12}>
                <h1 className="contact-form__heading">
                  Which of the following Creative Connex Services are of
                  interest to you?
                </h1>
                <FormControl style={{ width: "100%" }}>
                  <Autocomplete
                    value={selectedServices}
                    onChange={(event, newSelectedServices) => {
                      setSelectedServices(newSelectedServices);
                    }}
                    multiple
                    id="services"
                    options={products.map((product) => product.name)}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Services" fullWidth />
                    )}
                  />
                </FormControl>
              </Grid>

              {/* <Grid item xs={12} sm={12}>
                <Autocomplete
                  freeSolo
                  id="reason"
                  inputValue={values.reason}
                  value={values.reason}
                  onInputChange={(_e, value) => {
                    setFieldValue("reason", value);
                  }}
                  options={REASON_OPTIONS.map((option) => option)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      error={Boolean(errors.reason)}
                      helperText={errors.reason}
                      fullWidth
                      label="Why do you want to write a book?"
                      multiline
                      rows={2}
                    />
                  )}
                />
              </Grid> */}

              <Grid item xs={12} sm={12}>
                <Autocomplete
                  freeSolo
                  id="foundBy"
                  inputValue={values.foundBy}
                  value={values.foundBy}
                  onInputChange={(_e, value) => {
                    setFieldValue("foundBy", value);
                  }}
                  options={FOUNDBY_OPTIONS.map((option) => option)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.question_text}
                      variant="outlined"
                      error={Boolean(errors.foundBy)}
                      helperText={errors.foundBy}
                      fullWidth
                      label="How did you hear about Creative Connex?"
                      multiline
                      rows={2}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} container justify="center">
                <Button
                  className={classes.button}
                  variant="contained"
                  color="#F49D39"
                  disableElevation
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
            <Dialog
              open={open}
              onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Form Submitted</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Your info has been sent! Keep an eye on your email for a
                  response.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="#F49D39">
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </ThemeProvider>
        </Form>
      )}
    </Formik>
  );
}
