import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import privacyPolicy from "../imgs/pdfs/Privacy-Policy.pdf";
import faq from "../imgs/pdfs/FAQ.pdf";

class Footer extends Component {
  state = {};
  render() {
    return (
      <footer id="footer" className="footer">
        <div className="footer-content footer-container">
          <div className="footer__section">
            <h3 className="footer__heading">Need Help?</h3>
            <div className="footer__divider"></div>

            <a className="footer__link" target="_blank" href={privacyPolicy}>
              Privacy Policy
            </a>

            <a className="footer__link" target="_blank" href={faq}>
              FAQs
            </a>

            <a className="footer__link" href={"/contact/professional"}>
              Careers
            </a>
          </div>

          <div className="footer__section">
            <h3 className="footer__heading">Affiliated</h3>
            <div className="footer__divider"></div>
            <a
              className="footer__link"
              href="http://mugwumps.ca/"
              target="_blank"
              rel="noopener"
            >
              Mugwumps
            </a>
            <a className="footer__link" href="#" rel="noopener">
              Affiliate Program
            </a>
            <a className="footer__link" href="#" rel="noopener">
              Our Affiliates
            </a>
          </div>

          <div className="footer__section">
            <h3 className="footer__heading">Socials</h3>
            <div className="footer__divider"></div>
            <div className="footer__socials">
              <a
                className="footer__link"
                href="https://www.facebook.com/Creative-Connex-105873884736250/?view_public_for=105873884736250"
                target="_blank"
                rel="noopener"
              >
                <i className="fa fa-facebook-square"></i>
              </a>
              <a
                className="footer__link"
                href="https://www.instagram.com/creative_connex/"
                target="_blank"
                rel="noopener"
              >
                <i className="fa fa-instagram"></i>
              </a>
              <a
                className="footer__link"
                href="https://www.twitter.com/creative_connex"
                target="_blank"
                rel="noopener"
              >
                <i className="fa fa-twitter"></i>
              </a>
              <a
                className="footer__link"
                href="https://www.linkedin.com/company/creativeconnex"
                target="_blank"
                rel="noopener"
              >
                <i className="fa fa-linkedin"></i>
              </a>
            </div>
          </div>
          {/* 
          <div className="footer__section wide">
            <h3 className="footer__heading">Stay Informed &amp; Up to Date!</h3>
            <div className="footer__divider"></div>
            <a className="footer__link" href="/contact">
              Sign-up for our bi-monthly newsletter!
            </a>
            <h4
              style={{ marginBottom: "2rem" }}
              className="footer__sub-heading"
            >
              Sign up for our bi-monthly newsletter!
            </h4>
            <a
              className="footer__mail-button-v2"
              href="https://mailchi.mp/c720c4638c97/newsletter-signup"
              target="_blank"
            >
              Sign up
              <i className="fa fa-paper-plane" aria-hidden="true"></i>
            </a>
          </div> */}
        </div>
        {/* 


        <div className="site-footer--container">
          <div className="footer-inner subfooter container">
            <div className="subfooter-widget">
              <div className="subfooter-widget-section">
                <div className="subfooter-widget-section-title">
                  Need Help?
                </div>
                <ul className="subfooter-widget-section-tag">
                  <li>
                    <a href="/about">Reach Out</a>
                  </li>
                  <li>
                    <a href="/contact">FAQs</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="subfooter-widget">
              <div className="subfooter-widget-section">
                <div className="subfooter-widget-section-title">
                  Affiliated
                </div>
                <ul className="subfooter-widget-section-tag">
                  <li>
                    <a href="#">Crack the Cover</a>
                  </li>
                  <li>
                    <a href="http://mugwumps.ca/">Mugwumps</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="subfooter-widget">
              <div className="subfooter-widget-section">
                <div className="subfooter-widget-section-title">
                  Socials
                </div>
                <ul className="subfooter-widget-section-tag">
                  <li>
                    <a href="https://www.facebook.com/creativeconnex">Facebook</a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/creativeconnex/">LinkedIn</a>
                  </li>
                  <li>
                    <a href="#">Twitter</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="subfooter-widget">
              <div className="subfooter-widget-section">
                <div className="subfooter-widget-section-title">
                  News &amp; Updates
                </div>
                <div className="subfooter-widget-section-subtitle">
                  Sign up to get updates on newsletter
                </div>
                <div className="subfooter-widget-section-newsletter">
                  <div className="subfooter-widget-section-input">
                    <input type="text" placeholder="Enter your email" />
                    <span className="subfooter-widget-section-input-btn">
                      <button type="button">
                        <BsEnvelopeFill />
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-inner footer">
            <hr />
            <div className="footer-inner footer container">
              <div className="footer-inner-section">
                <p>
                  © 2020, Creative Connex Powered by{" "}
                  <a href="#about">Creative Connex</a>
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </footer>
    );
  }
}

export default Footer;
