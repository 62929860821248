import React, { Component } from "react";
import teamMember1 from "../../imgs/the-team/steve-headshot.jpeg";
import teamMember2 from "../../imgs/the-team/anna-headshot.png";
import teamMember3 from "../../imgs/the-team/haley-headshot.png";
import teamMember4 from "../../imgs/the-team/teeka-headshot.png";
import teamMember5 from "../../imgs/the-team/kate-headshot.jpg";
import { useViewport } from "../../hooks/useViewport";
import { NavLink } from "react-router-dom";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReactMarkdown from "react-markdown";
import TeamCards from "./teamcards";
import CompanyInfo from "./companyInfo";

function ConnectSection(props) {
  const { width } = useViewport();

  return width < 768 ? (
    // #region Dekstop
    <section className="connect container">
      <div className="connect__story">
        <a className="anchor" id="story" />
        <ReactMarkdown>{props.bellowBanner}</ReactMarkdown>
        <div className="aboveTeam">
          <ReactMarkdown>{props.aboveTeam}</ReactMarkdown>
        </div>
      </div>
      <div className="connect__team">
        <a className="anchor" id="team" />
      </div>
      <TeamCards size="mobile"></TeamCards>
      <div className="about-cta">
        <div className="about-cta__text">
          <ReactMarkdown>{props.bellowTeam}</ReactMarkdown>
          {/* <h2>
            Are you an experienced, talented creative professional?
            <br />
            Are you interested in helping authors become successful?
          </h2>
          <h3>
            Professional writers, editors, illustrators, translators, graphic
            and layout designers are always welcome.
            <br />
            <br />
            Click "Join Our Team" below, complete and submit your Creative
            Connex profile and show us what you’ve got.
          </h3> */}

          <NavLink className="banner-button" to="/contact/professional">
            <a>Join Our Team</a>
          </NavLink>
        </div>
      </div>
    </section>
  ) : (
    // #endregion
    //#region Mobile
    <section className="connect container">
      <div className="connect__story">
        <a className="anchor" id="story" />
        <ReactMarkdown>{props.bellowBanner}</ReactMarkdown>
      </div>

      <div className="connect__team-text">
        <a className="anchor" id="team" />
        <ReactMarkdown>{props.aboveTeam}</ReactMarkdown>
      </div>
      <TeamCards size="desktop"></TeamCards>
      <div className="about-cta">
        <div className="about-cta__text">
          <ReactMarkdown>{props.bellowTeam}</ReactMarkdown>
          {/* <h2>
            Are you an experienced, talented creative professional?
            <br />
            Are you interested in helping authors become successful?
          </h2>
          <h3>
            Professional writers, editors, illustrators, translators, graphic
            and layout designers are always welcome.
            <br />
            <br />
            Click "Join Our Team" below, complete and submit your Creative
            Connex profile and show us what you’ve got.
          </h3> */}

          <NavLink className="banner-button" to="/contact/professional">
            Join Our Team
          </NavLink>
        </div>
      </div>
    </section>
    //#endregion
  );
}

export default ConnectSection;
