import React, { Component } from "react";
import SVGImg from "../svg/marketplace/imgs";

class Book extends Component {
  state = {};
  render() {
    return (
      <div className="book">
        <SVGImg name={this.props.item.image} className="book__image" />
        <div className="book__content">
          <div className="book__content book__content--avatar"></div>
          <div className="book__content book__content--body">
            <h3>{this.props.item.title}</h3>
            <p>{this.props.item.author}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Book;
