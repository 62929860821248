import React from "react";
import {
  makeStyles,
  Checkbox,
  FormControlLabel,
  Grid,
  Divider,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import InfoTooltip from "./utils/InfoTooltip";
import { createProductLabel } from "./utils/bookForm";

const BookItemSelection = ({ products, setProducts }) => {
  const classes = useStyles();
  if (!products) {
    return <></>;
  }

  // function for updating state with checked value
  const handleCheck = (product) => {
    // create variable to hold new state
    let newState = [...products];

    // update checked property of the selected service in new state
    const indexToUpdate = products.indexOf(product);
    newState[indexToUpdate].checked = !newState[indexToUpdate].checked;

    // apply new state
    setProducts(newState);
  };

  // function for getting tooltip for a product
  const createProductTooltip = (product) => {
    // determine price text
    let priceText = "";
    switch (product.metadata.multiplier) {
      case "words":
        priceText = `Service Fee: ${product.price} cents / word`;
        break;
      case "pages":
        priceText = `Service Fee: $${(product.price / 100).toFixed(2)} / page`;
        break;
      case "none":
        priceText = `Service Fee: $${(product.price / 100).toFixed(2)}`;
        break;
      default:
        break;
    }

    if (!!product.metadata.pages && !!product.metadata.perPage) {
      priceText = `${priceText} up to ${product.metadata.pages} pages, then $${(
        product.metadata.perPage / 100
      ).toFixed(2)} per page`;
    }

    const minimumPriceText = !!product.metadata.minimum
      ? `Minimum total Service Fee: $${(product.metadata.minimum / 100).toFixed(
          2
        )}`
      : undefined;

    return (
      <div>
        {product.description}
        <br />
        <br />
        {priceText}
        {minimumPriceText && <div>{minimumPriceText}</div>}
      </div>
    );
  };

  function handleRadioCheck(product) {
    // Go through each product
    const newState = products.map((iProduct) => {
      // If product has same category
      if (iProduct.metadata.category === product.metadata.category) {
        // If product is the same
        if (iProduct === product) {
          // Flip that products checked value
          return {
            ...iProduct,
            checked: !iProduct.checked,
          };
        } else {
          // Otherwise, make the product checked as false
          return {
            ...iProduct,
            checked: false,
          };
        }
      } else {
        return iProduct;
      }
    });

    // apply new state
    setProducts(newState);
  }

  // get checkbox groups for different multipliers (words, pages, none)
  const perWordProducts = products.filter((product) => {
    return product.metadata.multiplier === "words";
  });

  const perPageProducts = products.filter((product) => {
    return product.metadata.multiplier === "pages";
  });

  const flatFeeProducts = products.filter((product) => {
    return product.metadata.multiplier === "none";
  });

  // setup dyanmic checkboxes for per word products
  let perWordCheckboxes = [];
  let perWordRadioGroupings = [];
  perWordProducts.forEach((product) => {
    if (product.metadata.category) {
      // Find index of the grouping
      const groupingIndex = perWordRadioGroupings.findIndex(
        ({ category }) => category === product.metadata.category
      );

      const radioButtonComponent = (
        <Grid container alignItems="center">
          <FormControlLabel
            className={classes.radio_container}
            key={product._id}
            value={product._id}
            control={
              <Radio
                onClick={() => handleRadioCheck(product)}
                checked={Boolean(product.checked)}
              />
            }
            label={createProductLabel(product.name)}
          />
          <InfoTooltip help tooltip={createProductTooltip(product)} />
        </Grid>
      );

      // If grouping does not exist
      if (groupingIndex < 0) {
        // add new category and product
        perWordRadioGroupings.push({
          category: product.metadata.category,
          items: [radioButtonComponent],
        });
      } else {
        perWordRadioGroupings[groupingIndex].items.push(radioButtonComponent);
      }
    } else {
      // create checkbox component
      const checkboxComponent = (
        <Grid key={product._id} container justify="center" alignItems="center">
          <FormControlLabel
            className={classes.checkbox_container}
            control={
              <Checkbox
                checked={Boolean(product.checked)}
                onChange={() => handleCheck(product)}
                name={product.name}
              />
            }
            label={product.name}
          />
          <InfoTooltip help tooltip={createProductTooltip(product)} />
        </Grid>
      );
      perWordCheckboxes.push(checkboxComponent);
    }
  });

  // setup dyanmic checkboxes for per page products
  let perPageCheckboxes = [];
  const perPageRadioGroupings = [];
  perPageProducts.forEach((product) => {
    if (product.metadata.category) {
      // Find index of the grouping
      const groupingIndex = perPageRadioGroupings.findIndex(
        ({ category }) => category === product.metadata.category
      );

      const radioButtonComponent = (
        <Grid container alignItems="center">
          <FormControlLabel
            className={classes.radio_container}
            key={product._id}
            value={product._id}
            control={
              <Radio
                onClick={() => handleRadioCheck(product)}
                checked={Boolean(product.checked)}
              />
            }
            label={createProductLabel(product.name)}
          />
          <InfoTooltip help tooltip={createProductTooltip(product)} />
        </Grid>
      );

      // If grouping does not exist
      if (groupingIndex < 0) {
        // add new category and product
        perPageRadioGroupings.push({
          category: product.metadata.category,
          items: [radioButtonComponent],
        });
      } else {
        perPageRadioGroupings[groupingIndex].items.push(radioButtonComponent);
      }
    } else {
      // create checkbox component
      const checkboxComponent = (
        <Grid key={product._id} container justify="center" alignItems="center">
          <FormControlLabel
            className={classes.checkbox_container}
            control={
              <Checkbox
                checked={Boolean(product.checked)}
                onChange={() => handleCheck(product)}
                name={product.name}
              />
            }
            label={product.name}
          />
          <InfoTooltip help tooltip={createProductTooltip(product)} />
        </Grid>
      );
      perPageCheckboxes.push(checkboxComponent);
    }
  });

  // setup dyanmic checkboxes for flat fee products
  let flatFeeCheckboxes = [];
  const flatFeeRadioGroupings = [];
  flatFeeProducts.forEach((product) => {
    if (product.metadata.category) {
      // Find index of the grouping
      const groupingIndex = flatFeeRadioGroupings.findIndex(
        ({ category }) => category === product.metadata.category
      );

      const radioButtonComponent = (
        <Grid container alignItems="center">
          <FormControlLabel
            className={classes.radio_container}
            key={product._id}
            value={product._id}
            control={
              <Radio
                onClick={() => handleRadioCheck(product)}
                checked={Boolean(product.checked)}
              />
            }
            label={createProductLabel(product.name)}
          />
          <InfoTooltip help tooltip={createProductTooltip(product)} />
        </Grid>
      );

      // If grouping does not exist
      if (groupingIndex < 0) {
        // add new category and product
        flatFeeRadioGroupings.push({
          category: product.metadata.category,
          items: [radioButtonComponent],
        });
      } else {
        flatFeeRadioGroupings[groupingIndex].items.push(radioButtonComponent);
      }
    } else {
      // create checkbox component
      const checkboxComponent = (
        <Grid key={product._id} container justify="center" alignItems="center">
          <FormControlLabel
            className={classes.checkbox_container}
            control={
              <Checkbox
                checked={Boolean(product.checked)}
                onChange={() => handleCheck(product)}
                name={product.name}
              />
            }
            label={product.name}
          />
          <InfoTooltip help tooltip={createProductTooltip(product)} />
        </Grid>
      );
      flatFeeCheckboxes.push(checkboxComponent);
    }
  });

  return (
    <Grid className={classes.container} container>
      <Grid item xs={12} container direction="column" alignItems="center">
        {perWordCheckboxes}
      </Grid>

      <Grid item xs={12} container direction="column" alignItems="center">
        {perWordRadioGroupings.map((grouping) => (
          <RadioGroup
            className={classes.radio_group}
            aria-label={grouping.category}
            name={grouping.category}
            key={grouping.category}
          >
            <Grid container direction="column" alignItems="center">
              {grouping.items}
            </Grid>
          </RadioGroup>
        ))}
      </Grid>

      <Divider className={classes.divider} />

      <Grid item xs={12} container direction="column" alignItems="center">
        {perPageCheckboxes}
      </Grid>

      <Grid item xs={12} container direction="column" alignItems="center">
        {perPageRadioGroupings.map((grouping) => (
          <RadioGroup
            className={classes.radio_group}
            aria-label={grouping.category}
            name={grouping.category}
            key={grouping.category}
          >
            {grouping.items}
          </RadioGroup>
        ))}
      </Grid>

      <Divider className={classes.divider} />

      <Grid item xs={12} container direction="column" alignItems="center">
        {flatFeeCheckboxes}
      </Grid>

      <Grid item xs={12} container direction="column" alignItems="center">
        {flatFeeRadioGroupings.map((grouping) => (
          <>
            <RadioGroup
              className={classes.radio_group}
              aria-label={grouping.category}
              name={grouping.category}
              key={grouping.category}
            >
              {grouping.category === "illustration" && (
                <h1 className="illustration-heading">
                  Children's Book Development Value Bundles
                </h1>
              )}
              {grouping.items}
            </RadioGroup>
          </>
        ))}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  container: { width: "100%" },
  checkbox_container: {
    margin: "1rem 0",
    marginRight: "1rem",
    textAlign: "start",
    width: "50rem",
    "@media (max-width: 700px)": {
      width: "40rem",
    },
    "@media (max-width: 600px)": {
      width: "30rem",
    },
  },
  radio_container: {
    margin: "1rem 0",
    marginRight: "1rem",
    textAlign: "start",
    width: "50rem",
    "@media (max-width: 700px)": {
      width: "40rem",
    },
    "@media (max-width: 600px)": {
      width: "30rem",
    },
  },
  radio_group: {
    borderRadius: "2rem",
    backgroundColor: "#EEE",
    margin: "1rem 0",
    padding: "1rem",
  },
  divider: {
    height: "1px",
    width: "100%",
  },
  tooltip_container: {
    "@media (max-width: 500px)": {
      display: "none",
    },
  },
});

export default BookItemSelection;
