import React from "react";
import Logo from "../imgs/logo.png";
import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import BackToTop from "../components/backToTop";
// import { IconContext } from "react-icons";
// import { FiSearch } from "react-icons/fi";
// import { RiShoppingBagLine } from "react-icons/ri";
import { useViewport } from "../hooks/useViewport";
import { useEffect, useState, useRef } from "react";
import { platformUrl } from "../utils/url";
import faq from "../imgs/pdfs/FAQ.pdf";

function Header(props) {
  const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
      };
    }, [ref, handler]);
  };

  const [open, setOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));
  const menuStyle = {
    transform: open ? "translateX(0)" : "translateX(100%)",
  };

  const StyledBurger = styled.button`
    position: absolute;
    top: 4rem;
    right: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 4rem;
    height: 4rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10000;

    &:focus {
      outline: none;
    }

    div {
      width: 4rem;
      height: 0.3rem;
      background: ${open ? "#000" : "#000"};
      border-radius: 10px;
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 1px;

      :first-child {
        transform: ${open ? "rotate(45deg)" : "rotate(0)"};
      }

      :nth-child(2) {
        opacity: ${open ? "0" : "1"};
        transform: ${open ? "translateX(20px)" : "translateX(0)"};
      }

      :nth-child(3) {
        transform: ${open ? "rotate(-45deg)" : "rotate(0)"};
      }
    }
  `;

  const { width } = useViewport();
  return width < 768 ? (
    <header className="mobile-header">
      <BackToTop />
      <div className="hamburger">
        <div className="hamburger__content">
          <NavLink exact to="/home" className="header-logo">
            <img className="hamburger__logo" src={Logo} alt="" />
          </NavLink>
          <StyledBurger
            className="hamburger__button"
            onClick={() => setOpen(!open)}
          >
            <div />
            <div />
            <div />
          </StyledBurger>
        </div>
      </div>
      <nav className="mobile-nav" ref={node} style={menuStyle}>
        <ul className="mobile-nav__menu">
          <NavLink
            onClick={() => setOpen(false)}
            activeClassName="active"
            to="/home"
          >
            <li>Home</li>
          </NavLink>
          <NavLink
            onClick={() => setOpen(false)}
            activeClassName="active"
            to="/about"
          >
            <li>About Us</li>
          </NavLink>
          <NavLink
            onClick={() => setOpen(false)}
            activeClassName="active"
            to="/services"
          >
            <li>Services</li>
          </NavLink>
          <NavLink
            onClick={() => setOpen(false)}
            activeClassName="active"
            to="/estimator"
          >
            <li>Estimator</li>
          </NavLink>
          {/* <NavLink activeClassName="active" to="/bookstore">
            <li>Bookstore</li>
          </NavLink>
          <NavLink activeClassName="active" to="/education">
            <li>Connex Academy</li>
          </NavLink> */}
          {/* <NavLink activeClassName="active" to="/promotions">
            <li>Promotions</li>
          </NavLink> */}
          <NavLink
            onClick={() => setOpen(false)}
            activeClassName="active"
            to="/contact"
          >
            <li>Contact</li>
          </NavLink>

          <a href={platformUrl}>
            <li>Log In</li>
          </a>

          <a href={platformUrl}>
            <li>Sign Up</li>
          </a>

          <div className="mobile-header__socials">
            <a
              className="mobile-header__social"
              href="https://www.facebook.com/Creative-Connex-105873884736250/?view_public_for=105873884736250"
              target="_blank"
              rel="noopener"
            >
              <i className="fa fa-facebook-square"></i>
            </a>
            <a
              className="mobile-header__social"
              href="https://www.instagram.com/creative_connex/"
              target="_blank"
              rel="noopener"
            >
              <i className="fa fa-instagram"></i>
            </a>
          </div>
          <div className="mobile-header__socials">
            <a
              className="mobile-header__social"
              href="https://twitter.com/creative_connex"
              target="_blank"
              rel="noopener"
            >
              <i className="fa fa-twitter"></i>
            </a>
            <a
              className="mobile-header__social"
              href="https://www.linkedin.com/company/creativeconnex/"
              target="_blank"
              rel="noopener"
            >
              <i className="fa fa-linkedin"></i>
            </a>
          </div>
        </ul>
      </nav>
    </header>
  ) : (
    <header id="header" className="header">
      <BackToTop />

      <nav className="header-navbar header-container">
        <div className="header-navbar__left">
          <NavLink exact to="/home" className="header-logo">
            <img className="header-navbar-logo" src={Logo} alt="" />
          </NavLink>
        </div>
        <div className="header-navbar__right">
          <div className="header-nav-links">
            <div className="header-nav-link">
              <NavLink
                activeClassName="header-nav-link__anchor--selected"
                className="header-nav-link__anchor"
                exact
                to="/home"
              >
                Home
              </NavLink>
              <div
                name="hover-block"
                className="header-nav-link__hover-block"
              ></div>
            </div>

            <div className="header-nav-link">
              <NavLink
                activeClassName="header-nav-link__anchor--selected"
                className="header-nav-link__anchor"
                exact
                to="/about"
              >
                About Us
              </NavLink>
              <div
                name="hover-block"
                className="header-nav-link__hover-block"
              ></div>
            </div>

            <div className="header-nav-link">
              <NavLink
                activeClassName="header-nav-link__anchor--selected"
                className="header-nav-link__anchor"
                exact
                to="/services"
              >
                Services
              </NavLink>
              <div className="dropdown-box wide">
                <NavHashLink exact to="/services/professional-writing">
                  Professional Writing
                </NavHashLink>
                <NavHashLink exact to="/services/professional-editing">
                  Professional Editing
                </NavHashLink>
                <NavHashLink exact to="/services/custom-illustration">
                  Custom Illustration
                </NavHashLink>
                <NavHashLink exact to="/services/custom-design">
                  Custom Design
                </NavHashLink>
                <NavHashLink exact to="/services/other-services">
                  Other Services
                </NavHashLink>
              </div>
            </div>

            <div className="header-nav-link">
              <NavLink
                activeClassName="header-nav-link__anchor--selected"
                className="header-nav-link__anchor"
                exact
                to="/estimator"
              >
                Book $ Estimator
              </NavLink>
              <div
                name="hover-block"
                className="header-nav-link__hover-block"
              ></div>
            </div>

            {/* <div className="header-nav-link">
              <NavLink activeClassName="header-nav-link__anchor--selected" className="header-nav-link__anchor" exact to="/bookstore">
                Bookstore
                </NavLink>
            </div>

            <div className="header-nav-link wide">
              <NavLink activeClassName="header-nav-link__anchor--selected" className="header-nav-link__anchor" exact to="/education">
                Connex Academy
                </NavLink>
              <div className="dropdown-box">
                <NavHashLink exact to="/education#education">
                  Video Courses
                  </NavHashLink>
                <NavHashLink exact to="/education#education">
                  Writing &amp; Publishing Tools
                  </NavHashLink>
                <NavHashLink exact to="/education#education">
                  Misc. Resources
                  </NavHashLink>
              </div>
            </div> */}

            {/* <div className="header-nav-link">
              <NavLink activeClassName="header-nav-link__anchor--selected" className="header-nav-link__anchor" exact to="/promotions">
                Promotions
                </NavLink>
              <div name="hover-block" className="header-nav-link__hover-block"></div>
            </div> */}

            <div className="header-nav-link">
              <NavLink
                activeClassName="header-nav-link__anchor--selected"
                className="header-nav-link__anchor"
                exact
                to="/contact"
              >
                Contact
              </NavLink>
              <div className="dropdown-box">
                <a target="_blank" href={faq}>
                  FAQs
                </a>
                <NavHashLink exact to="/contact/professional">
                  Careers
                </NavHashLink>
              </div>
            </div>
          </div>
        </div>
        <div className="header__extras">
          <div className="login-button">
            <a href={platformUrl}>Log In</a>
          </div>

          {/* <div className="sign-up-button">
            <a href={platformUrl}>Sign Up</a>
          </div> */}

          <div className="header__socials">
            <a
              className="header__social"
              href="https://www.facebook.com/Creative-Connex-105873884736250/?view_public_for=105873884736250"
              target="_blank"
              rel="noopener"
            >
              <i className="fa fa-facebook-square"></i>
            </a>
            <a
              className="header__social"
              href="https://www.instagram.com/creative_connex/"
              target="_blank"
              rel="noopener"
            >
              <i className="fa fa-instagram"></i>
            </a>
            <a
              className="header__social"
              href="https://twitter.com/creative_connex"
              target="_blank"
              rel="noopener"
            >
              <i className="fa fa-twitter"></i>
            </a>
            <a
              className="header__social"
              href="https://www.linkedin.com/company/creativeconnex/"
              target="_blank"
              rel="noopener"
            >
              <i className="fa fa-linkedin"></i>
            </a>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
