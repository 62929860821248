import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import classnames from "classnames";

import InfoTooltip from "./utils/InfoTooltip";

const BookPriceSummaryLineItem = ({ leftText, rightText, bold, tooltip }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.container} container justify="space-between">
      <Grid item xs={10} container>
        <Typography
          className={classnames({
            [classes.left_text]: true,
            [classes.bold]: bold,
          })}
          variant="body1"
        >
          {leftText}
        </Typography>
        {!!tooltip && <InfoTooltip tooltip={tooltip} />}
      </Grid>
      <Typography className={bold ? classes.bold : ""} variant="body1">
        ${rightText}
      </Typography>
    </Grid>
  );
};

const useStyles = makeStyles({
  container: {
    "@media (max-width: 500px)": {
      marginBottom: "0.5rem",
    },
  },
  left_text: {
    marginRight: "0.5rem",
    textAlign: "left",
  },
  bold: {
    fontWeight: 600,
  },
});

export default BookPriceSummaryLineItem;
