import React from "react";
import { NavLink } from "react-router-dom";

function PagePreview(props) {

    return (
        <div className="page-preview">
            <h1 dangerouslySetInnerHTML={{ __html: props.title }}></h1>
            <img src={props.image} alt="" />
            <p>{props.body}</p>
            <NavLink className="card-button" to="#">
                Coming Soon
            </NavLink>
        </div >
    );
}
export default PagePreview;
