import React from "react";
import ProfessionalContactForm from "./professionalContactForm";
import "../../scss/pages/_contact.scss";
import "normalize.css";


export default function ProfessionalContact(props) {
    return (
        <div className="professional-contact">
            <h1 className="professional-contact__heading">Interested in joining our creative team?<br />Tell us a bit about yourself!</h1>
            <ProfessionalContactForm />
        </div>
    );
}
