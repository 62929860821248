import React from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import HelpIcon from "@material-ui/icons/HelpTwoTone";

const InfoTooltip = ({ tooltip, help }) => {
  const classes = useStyles();
  const icon = help ? (
    <HelpIcon className={classes.icon} />
  ) : (
    <InfoIcon className={classes.icon} />
  );

  return (
    <Tooltip title={<span className={classes.tooltip_text}>{tooltip}</span>}>
      {icon}
    </Tooltip>
  );
};

const useStyles = makeStyles({
  icon: {
    fontSize: "2.5rem",
    color: "#C4C4C4",
  },
  tooltip_text: {
    fontSize: "1.2rem",
  },
});

export default InfoTooltip;
