import React, { Component } from "react";
import Card from "../../components/flipCard";
import CardSection from "../../components/cardSection";
import ScrollMenu from "react-horizontal-scrolling-menu";

class Connect extends Component {
  constructor(props) {
    super(props);
    this.state = { selected:1 };
  }
  onSelect = (key) => {
    this.setState({ selected: key });
  };
  render() {
    const data = [
      {
        id: 1,
        image: "editors",
        title: "Editors",
      },
      {
        id: 2,
        image: "illustrators",
        title: "Illustrators",
      },
      {
        id: 3,
        image: "writers",
        title: "Writers",
      },
      {
        id: 4,
        image: "marketers",
        title: "Marketers",
      },
    ];
    const myStyle = {
      width: "85%",
      margin: "0 auto",
      padding: "40px 0 30px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "consolas",
      overflowX: "auto",
    };
    const props = {
      heading: "Choose from team of professionals",
      subheading:
        "Choose which professional to connect with at each step of your book's development.",
      btnText: "Get Connected",
    };
    
    const Menu = (selected) =>
      data.map((item) => {
        return <Card key={item.id} item={item} selected={selected} />;
      });

    const Arrow = ({ text, className }) => {
      return <div className={className}>{text}</div>;
    };
    const ArrowLeft = Arrow({ text: "<", className: "arrow-prev" });
    const ArrowRight = Arrow({ text: ">", className: "arrow-next" });
    const { selected } = this.state;
    // Create menu from items
    const menu = Menu(selected);

    return (
      <div className="connect_with_professionals">
        <CardSection style={myStyle} {...props}>
        <ScrollMenu
          data={menu}
          arrowLeft={ArrowLeft}
          arrowRight={ArrowRight}
          selected={selected}
          onSelect={this.onSelect}
          alignCenter={true}
        />
        </CardSection>
      </div>
    );
  }
}

export default Connect;
