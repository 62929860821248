import React, { Component } from "react";

class GetStarted extends Component {
  state = {};
  render() {
    return (
      <section className="contact-cta">
        {/* <h1>So what are you waiting for?</h1> */}
        <h1>{this.props.title}</h1>
        <a className="banner-button" href={this.props.link}>
        {this.props.button}
        </a>
      </section>
    );
  }
}

export default GetStarted;
