import React from "react";
import {
  Button,
  Fade,
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core";
import UpArrow from "@material-ui/icons/ArrowUpward";
import { useEffect, useState } from "react";
const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#F49D39",
      },
    },
  });

  useEffect(() => {
    document.addEventListener("scroll", function () {
      toggleVisibility();
    });
  }, []);
  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const handleBackToTopClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Fade
        className={classes.container}
        in={isVisible}
        timeout={{ enter: 1000 }}
      >
        <Button
          onClick={handleBackToTopClick}
          className={classes.button}
          variant="contained"
          style={{ display: isVisible ? "inline-flex" : "none" }}
          startIcon={<UpArrow />}
          color="primary"
        >
          Back to top
        </Button>
      </Fade>
    </ThemeProvider>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    position: "fixed",
    bottom: "25%",
    right: "1rem",
    zIndex: 1000,
    "@media (max-width: 1400px)": {
      display: "none !important",
    },
  },
  button: {
    display: "none",
  },
}));

export default BackToTop;
