import React from "react";
import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Home from "./page/home/home";
import Header from "./page/header";
import Connect from "./page/connect/connect";
import Services from "./page/services/services";
import Promotions from "./page/promotions/promotions";
import Education from "./page/education/education";
import Bookstore from "./page/bookstore/bookstore";
import Contact from "./page/contact/contact";
import Estimator from "./page/estimator/estimator";
import ProfessionalContact from "./page/contact/professionalContact";
import Footer from "./page/footer";
import Publishing from "./page/services/publishing/publishing";
import Calendar from "./page/services/calendar/calendar";
// import Community from "./page/community/community";
// import Service from "./page/pricing/Service/service";
import ScrollToTop from "./utils/scrollToTop";
import ProfessionalWriting from "./page/services/serviceTypePages/professionalWriting";
import ProfessionalEditing from "./page/services/serviceTypePages/professionalEditing";
import CustomIllustration from "./page/services/serviceTypePages/customIllustration";
import CustomDesign from "./page/services/serviceTypePages/customDesign";
import OtherServices from "./page/services/serviceTypePages/otherServices";
import ContestPopup from "./components/ContestPopup";
import RouteChangeTracker from "./components/analytics/RouteChangeTracker";
import VidyardEmbed from "@vidyard/embed-code";

// set up google analytics
import ReactGA from "react-ga";
const TRACKING_ID = "UA-196736999-1";

function App() {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview("/");
    ReactGA.pageview(window.location.pathname + window.location.search);
    VidyardEmbed.api.renderDOMPlayers();
  }, []);

  const printHome = () => {
    return <Home />;
  };

  return (
    <>
      <Router>
        <ScrollToTop />
        {/* <ContestPopup /> */}
        <RouteChangeTracker />
        <Header />

        <Switch>
          <Route path="/home" component={printHome} />
          <Route exact path="/about" component={Connect} />
          <Route exact path="/services" component={Services} />

          {/* Services sub-directories */}
          <Route
            exact
            path="/services/professional-writing"
            component={ProfessionalWriting}
          />
          <Route
            exact
            path="/services/professional-editing"
            component={ProfessionalEditing}
          />
          <Route
            exact
            path="/services/custom-illustration"
            component={CustomIllustration}
          />
          <Route
            exact
            path="/services/custom-design"
            component={CustomDesign}
          />
          <Route
            exact
            path="/services/other-services"
            component={OtherServices}
          />

          <Route exact path="/promotions" component={Promotions} />
          {/* <Route exact path="/services/publishing" component={Publishing} />
          <Route exact path="/services/calendar" component={Calendar} />
          <Route exact path="/bookstore" component={Bookstore} />
          <Route exact path="/education" component={Education} /> */}
          <Route exact path="/estimator" component={Estimator} />
          <Route exact path="/contact" component={Contact} />
          <Route
            exact
            path="/contact/professional"
            component={ProfessionalContact}
          />
          {/* <Route path="/pricing/:serviceId" component={Service} /> */}
          <Redirect exact from="/" strict to="/home" />
          <Route path="*" exact={true} component={printHome} />
          {/* 404 error for pages */}
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
