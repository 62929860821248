import React, { Component } from "react";
import ReactMarkdown from 'react-markdown'
import linkAPI from "../../hooks/linkAPI";

// import infographic from "../../imgs/infographic_large.svg";
// import infographicMobile from "../../imgs/infographic_mobile.svg";
import { useViewport } from "../../hooks/useViewport";

function CompanyInfo({bellowBannerText, title, subtitle, img, mobileImg, imgAlt}) {
  const { width } = useViewport();
  return (
    <section className="company-info container">
      <div className="company-info-block">
        {/* <div className="company-info-block__image">
          <img src={this.props.item.image} alt="" />
        </div> */}
        <div className="company-info-block__text">
          {/* <h2>{title}</h2>
          <p style={{ textAlign: "center" }}>
            {subtitle}
          </p> */}
          <ReactMarkdown>{bellowBannerText}</ReactMarkdown>
        </div>
      </div>

      <div className="company-infographic">
        {/* <div className="company-info-block__image">
          <img src={this.props.item.image} alt="" />
        </div> */}
        <div className="company-infographic__text">
          <h2 className="orange-text">{title}</h2>
          <p style={{ textAlign: "center" }}>{subtitle}</p>
        </div>
        {img ? <img
          className="infographic"
          src={width < 768 ? linkAPI + mobileImg : linkAPI + img}
          alt= {imgAlt}
          draggable="false"
        /> :
         <div></div>}
      </div>

      {/* <div className="company-info-block"> */}
        {/* <div className="company-info-block__image">
          <img src={this.props.item.image} alt="" />
        </div> */}
        
        {/* <div className="company-info-block__text">
          <h2>Why work with us?</h2>
          <p style={{ textAlign: "center" }}>
            We understand that your book will be a proud achievement. Whether
            you want to publish fiction, nonfiction, memoir, children's, or
            business book, we've got you covered!
            <br />
            <br />
            We tell you what you need to know, not what you want to hear. We
            tell you what works, what needs work, and how to do the work.
          </p>
        </div> */}
      {/* </div> */}
    </section>
  );
}

export default CompanyInfo;
