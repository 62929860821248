import React from "react";

export default function Testimonial({ name, body }) {
  return (
    <div className="testimonial-container">
      <div className="testimonial">
        <i
          className="fa fa-quote-left testimonial__quote"
          aria-hidden="true"
        ></i>
        <div className="testimonial__text">
          <p className="testimonial__body">{body}</p>
          <p className="testimonial__name">{name}</p>
        </div>
      </div>
    </div>
  );
}
