const data = [
    {
      id: 1,
      image: "Freefall",
      title:"Freefall",
      author: "Wendy A. Lewis",
    },
    {
      id: 2,
      image: "Mugwumps",
      title: "Mugwumps",
      author: "Steve Sutherland",
    },
    {
        id: 3,
        image: "Star Catcher",
        title: "Star Catcher",
        author: "Coming Soon",
      },
      {
        id: 4,
        image: "Tap Water Tea",
        title: "Tap Water Tea",
        author: "Coming Soon",
      },
  ];
  
  export default data;