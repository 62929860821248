import React, { Component } from "react";
import SVGLogo from "../svg/icons/svgIcon";

class Card extends Component {
  render() {
    const ini = "card";
    return (
      <div className={ini}>
        <div className={ini + "__face face1"}>
          <div className={ini + "__content"}>
            <SVGLogo
              className={`${ini}__image ${this.props.item.image ? this.props.item.image.replace(/ /g, "_") : null
                }`}
              name={this.props.item.image}
            />
            <h3>{this.props.item.title}</h3>
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
