import React from "react";
import { makeStyles, Grid, Typography, Divider } from "@material-ui/core";

import BookPriceSummaryLineItem from "./BookPriceSummaryLineItem";
import {
  calculateAdminFee,
  addTax,
  // calculateDepositTotal,
  calculateServiceTotal,
  createProductLabel,
} from "./utils/bookForm";

const CC_FEE_COPY =
  "This 10% project fee enables us to provide you with ongoing expert support, tools, resources, access to education & training programs as well as our vast network of Creative Industry Professionals.";
const MANDATORY_EDITING_COPY =
  "This editing service is mandatory as part of our 'we write' process.";

const BookPriceSummary = ({
  products,
  pricePercentages,
  wordCount,
  pageCount,
  projectType,
}) => {
  const classes = useStyles();

  if (!products || !pricePercentages) {
    return <></>;
  }

  // variable to sum up total of all selected services
  let servicesTotal = 0;
  const mandatoryEditingFee = wordCount * pricePercentages.weWrite;

  // filter each type of product for different multipliers
  const checkedPerWordProducts = products.filter((product) => {
    // filter only products with words multiplier that are checked
    return product.checked && product.metadata.multiplier === "words";
  });

  const checkedPerPageProducts = products.filter((product) => {
    // filter only products with pages multiplier that are checked
    return product.checked && product.metadata.multiplier === "pages";
  });

  const checkedFlatFeeProducts = products.filter((product) => {
    // filter only products with no multiplier that are checked
    return product.checked && product.metadata.multiplier === "none";
  });

  // generate line items...
  // line items for per word section
  const perWordLineItems = checkedPerWordProducts.map((product) => {
    // get total for this service in dollars
    const serviceTotal = calculateServiceTotal(product, wordCount);
    // add to running total of all services
    servicesTotal += serviceTotal;
    const dollarAmount = (serviceTotal / 100).toFixed(2);
    return (
      <BookPriceSummaryLineItem
        key={product._id}
        leftText={`${product.name} x ${wordCount} words`}
        rightText={`${dollarAmount}`}
      />
    );
  });

  // line items for per page section
  const perPageLineItems = checkedPerPageProducts.map((product) => {
    // get total for this service in dollars
    const serviceTotal = calculateServiceTotal(product, pageCount);

    // add to running total of all services
    servicesTotal += serviceTotal;
    const dollarAmount = (serviceTotal / 100).toFixed(2);
    return (
      <BookPriceSummaryLineItem
        key={product._id}
        leftText={`${product.name} x ${pageCount} pages`}
        rightText={`${dollarAmount}`}
      />
    );
  });

  // line items for flat fee section
  const flatFeeLineItems = checkedFlatFeeProducts.map((product) => {
    // get total for this service in dollars
    let serviceTotal = product.price;
    if (!!product.metadata.pages && !!product.metadata.perPage) {
      // if service has maximum page logic then account for that
      const extraPages = pageCount - product.metadata.pages;
      if (extraPages > 0) {
        // if exceeds max pages
        serviceTotal = product.price + extraPages * product.metadata.perPage;
      }
    }
    // add to running total of all services
    servicesTotal += serviceTotal;
    const dollarAmount = (serviceTotal / 100).toFixed(2);
    return (
      <BookPriceSummaryLineItem
        key={product._id}
        leftText={createProductLabel(product.name)}
        rightText={`${dollarAmount}`}
      />
    );
  });

  // if interview book, add mandatory editing fee to services total
  servicesTotal =
    projectType === "interview"
      ? servicesTotal + mandatoryEditingFee
      : servicesTotal;

  // calculate admin fee
  const adminFee = calculateAdminFee(
    servicesTotal,
    pricePercentages.admin,
    pricePercentages.adminMinimum
  );

  // get tax just for display
  const totalTax = (servicesTotal + adminFee) * (pricePercentages.tax / 100);

  // calculate grand total of project by adding total services and admin fee, then adding tax to that
  const grandTotal = addTax(servicesTotal + adminFee, pricePercentages.tax);

  // calculate deposit total by adding 35% services total and admin fee, then adding tax to that
  // const depositTotal = calculateDepositTotal(
  //   servicesTotal,
  //   adminFee,
  //   pricePercentages.deposit,
  //   pricePercentages.tax
  // );

  // calculate remaining balance to be paid after deposit
  // const remainingBalance = grandTotal - depositTotal;

  return (
    <Grid className={classes.container} container>
      {/* Container for per word calculator section */}
      {perWordLineItems.length > 0 && (
        <Grid className={classes.calculator_section} item xs={12}>
          <Typography variant="h6">Per Word Services</Typography>
          <Divider />
          <Grid className={classes.line_item_container}>
            {perWordLineItems}
          </Grid>
        </Grid>
      )}

      {/* Container for per page calculator section */}
      {perPageLineItems.length > 0 && (
        <Grid className={classes.calculator_section} item xs={12}>
          <Typography variant="h6">Per Page Services</Typography>
          <Divider />
          <Grid className={classes.line_item_container}>
            {perPageLineItems}
          </Grid>
        </Grid>
      )}

      {/* Container for flat fee calculator section */}
      {flatFeeLineItems.length > 0 && (
        <Grid className={classes.calculator_section} item xs={12}>
          <Typography variant="h6">Flat Fee Services</Typography>
          <Divider />
          <Grid className={classes.line_item_container}>
            {flatFeeLineItems}
          </Grid>
        </Grid>
      )}

      {/* Container for final calculation calculator section */}
      <Grid item xs={12}>
        <Divider className={classes.divider_large} />

        <Grid className={classes.line_item_container}>
          {projectType === "interview" && (
            <BookPriceSummaryLineItem
              leftText={`Editor Interview, Manuscript Writing & Editing`}
              rightText={`${(mandatoryEditingFee / 100).toFixed(2)}`}
              tooltip={MANDATORY_EDITING_COPY}
            />
          )}
          <BookPriceSummaryLineItem
            leftText="Services Total"
            rightText={`${(servicesTotal / 100).toFixed(2)}`}
          />
          <BookPriceSummaryLineItem
            leftText="Creative Connex Fee"
            rightText={`${(adminFee / 100).toFixed(2)}`}
            tooltip={CC_FEE_COPY}
          />
          <BookPriceSummaryLineItem
            leftText={`HST (${pricePercentages.tax}%)`}
            rightText={`${(totalTax / 100).toFixed(2)}`}
          />
        </Grid>

        <Divider />

        <Grid className={classes.line_item_container}>
          <BookPriceSummaryLineItem
            bold
            leftText="Estimated Project Total"
            rightText={`${(grandTotal / 100).toFixed(2)}`}
          />
          {/* <BookPriceSummaryLineItem
            bold
            leftText="Required Deposit"
            rightText={`${(depositTotal / 100).toFixed(2)}`}
          /> */}
        </Grid>

        <Divider />

        {/* <Grid className={classes.line_item_container}>
          <BookPriceSummaryLineItem
            bold
            leftText="Balance Remaining (Estimated)"
            rightText={`${(remainingBalance / 100).toFixed(2)}`}
          />
        </Grid> */}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  container: {
    paddingTop: "1rem",
  },
  calculator_section: {
    marginBottom: "1rem",
  },
  divider_large: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    height: "3px",
  },
  line_item_container: {
    margin: "0.5rem",
  },
  bold: {
    fontWeight: 600,
  },
});

export default BookPriceSummary;
