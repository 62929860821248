import React, { Component } from "react";
import Book from "../../components/book";
import data from "../../data/home/marketplaceData";
import CardSection from "../../components/cardSection";

class Market extends Component {
  state = {};
  render() {
    const myStyle = {
      width: "85%",
      margin: "40px auto 0",
      position: "relative",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontFamily: "consolas",
      overflowX: "auto",
    };
    const props = {
      heading: "Creative Connex Marketplace",
      subheading:
        "Support authors by buying directly from the Creative Connex marketplace. Add your book to our marketplace with highest possible royalties.",
      btnText: "Explore More",
    };
    const connect = data.map((item) => <Book key={item.id} item={item} />);
    return (
      <div className="home__marketplace">
        <CardSection style={myStyle} {...props}>
          {connect}
        </CardSection>
      </div>
    );
  }
}

export default Market;
