import React, { Component } from "react";
import Card from "../../components/card";
import CardSection from "../../components/cardSection";

class Assistance extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const data = [
      {
        id: 1,
        image: "Writing Assistance",
        alt: "Writing Assistance",
        title: "Not written a book yet?",
        intro:
          "Having doubts or simply don't know where to start, then this is for  you. Put you're pen to paper and learn what it takes to write a children's book in this course.",
      },
      {
        id: 2,
        image: "Publishing Assistance",
        alt: "Self Publishing Assistance",
        title: "Thinking of self-publishing?",
        intro:
          "With our platform, get help at different points of your journey. Join the course to learn everything about self publishing a children's book in 2020.",
      },
      {
        id: 3,
        image: "Publisher Assistance",
        alt: "Previously Published Assistance",
        title: "Previously Published?",
        intro:
          "Learn how to reach more readers and sell more books with this course. Promote and Market Your Book Like a Pro.",
      },
    ];
    const props = {
      heading: "Get assistance every step of the process",
      subheading:
        "Not sure where to start? Get guidance and in-depth help from experienced professionals.",
      btnText: "Browse Classes",
    };
    const assitance = data.map((item) => <Card key={item.id} item={item} />);
    return (
      <div className="assitance">
        <CardSection {...props}>{assitance}</CardSection>
      </div>
    );
  }
}

export default Assistance;
