import React, { Component, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Banner from "../banner";
import PagePreview from "../../components/pagePreview";
import publishingImage from "../../imgs/svgs/services.svg";
import calendarImage from "../../imgs//svgs/student-saver.svg";
import Testimonial from "../../components/testimonial";
import BannerImage from "../../imgs/book_development.svg";
import ServicesImage from "../../imgs/services.svg";
import { platformUrl } from "../../utils/url";
import matrixImage1 from "../../imgs/youwrite_withoutbutton.png";
import matrixImage2 from "../../imgs/wewrite_withoutbutton.png";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import professionalWritingImage from "../../imgs/professional_writing.svg";
import professionalEditingImage from "../../imgs/professional_editing.svg";
import customIllustrationImage from "../../imgs/custom_illustration.svg";
import customDesignImage from "../../imgs/custom_design.svg";
import otherServicesImage from "../../imgs/connex_academy.svg";
import VidyardEmbed from "@vidyard/embed-code";
import linkAPI from "../../hooks/linkAPI";
import axios from "axios";
import ReactMarkdown from "react-markdown";

const Services = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    // VidyardEmbed.api.renderDOMPlayers();
    const getData = async () => {
      const res = await axios.get(linkAPI + "/api/services?populate=deep");
      setData(res.data.data.attributes);
    };
    getData();
  }, []);

  const bannerProps = {
    title: data ? data.Banner.Title : "",
    title2: data ? (data.Banner.Title2 ? data.Banner.Title2 : "") : "",
    body: data ? data.Banner.Description : "",
    buttonCopy: data
      ? data.Banner.Above_Button
        ? data.Banner.Above_Button
        : null
      : "",
    action: data ? data.Banner.Button : "",
    link: data ? data.Banner.Button_Link : "",
    video: data ? (data.Banner.Video ? true : false) : false,
    videoLink: data ? (data.Banner.Video ? data.Banner.Video : "") : "",
    videoID: data ? (data.Banner.VideoID ? data.Banner.VideoID : "") : "",
    image: data ? (data.Banner.Image.data ? true : false) : false,
    image: data
      ? data.Banner.Image.data
        ? data.Banner.Image.data.attributes.url
        : ""
      : "",
  };

  const publishingProps = {
    title: "Assisted<br/>Self-Publishing",
    body: "You don’t have to struggle and self-publish alone! We provide individuals who are interested in writing and publishing a book the opportunity to do so. We make the process easy, affordable, and fast.",
    image: publishingImage,
  };

  const calendarProps = {
    title: "Scan and Save<br/>Program",
    body: "Small businesses now more than ever need the support of their local community. Our innovative Small Business “Scan and Save” Community Marketplace is a forum that was developed to do just that!",
    image: calendarImage,
  };

  const testimonialProps = {
    name: data ? data.Quote.Person : "",
    body: data ? data.Quote.Text : "",
  };

  function Card(cardData) {
    return (
      <img
        className="matrix__image"
        src={linkAPI + cardData.Image.data.attributes.url}
        alt="Matrix of details"
        draggable="false"
      />
    );
  }
  const Programs = data
    ? data.Programs.map((item) => <Card key={item.id} {...item}></Card>)
    : "";

  function SCard(cardData) {
    return (
      <NavLink to={cardData.Link}>
        <div className="service-link-card">
          <img src={linkAPI + cardData.Image.data.attributes.url} />
          <h2>{cardData.Title}</h2>
        </div>
      </NavLink>
    );
  }
  const Services = data
    ? data.Services_Cards.map((item) => <SCard key={item.id} {...item}></SCard>)
    : "";
  return (
    <div className="services-wrapper">
      <Banner {...bannerProps} />
      <div className="services container">
        <ReactMarkdown>{data ? data.Bellow_Banner : ""}</ReactMarkdown>
        <div className="genres_list">
          <ReactMarkdown>{data ? data.Service_List : ""}</ReactMarkdown>
        </div>

        <Testimonial {...testimonialProps} />

        <h1>{data ? data.Bellow_Quote : ""}</h1>
        <div className="matrix">{Programs}</div>

        <a className="banner-button" href={data ? data.Button_Link : ""}>
          {data ? data.Button : ""}
        </a>

        <h1>{data ? data.Bellow_Programs : ""}</h1>
        <div className="service-link-cards">{Services}</div>
      </div>
    </div>
  );
};

export default Services;
