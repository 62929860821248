import React, { Component } from "react";
import Banner from "../../banner";
import { NavLink } from "react-router-dom";
import axios from "axios";
import linkAPI from "../../../hooks/linkAPI";
import ReactMarkdown from "react-markdown";

class ProfessionalWriting extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = { data: null };
  }
  componentDidMount() {
    axios
      .get(linkAPI + "/api/service-posts/1?populate=deep")
      .then((res) => res.data.data.attributes)
      .then((res) => this.setState({ data: res }));
  }

  render() {
    const bannerProps = {
      title: this.state.data ? this.state.data.Banner.Title : "",
      title2: this.state.data
        ? this.state.data.Banner.Title2
          ? this.state.data.Banner.Title2
          : ""
        : "",
      body: this.state.data ? this.state.data.Banner.Description : "",
      buttonCopy: this.state.data
        ? this.state.data.Banner.Above_Button
          ? this.state.data.Banner.Above_Button
          : null
        : "",
      action: this.state.data ? this.state.data.Banner.Button : "",
      link: this.state.data ? this.state.data.Banner.Button_Link : "",
      video: this.state.data
        ? this.state.data.Banner.Video
          ? true
          : false
        : false,
      videoLink: this.state.data
        ? this.state.data.Banner.Video
          ? this.state.data.Banner.Video
          : ""
        : "",
      videoID: this.state.data
        ? this.state.data.Banner.VideoID
          ? this.state.data.Banner.VideoID
          : ""
        : "",
      image: this.state.data
        ? this.state.data.Banner.Image.data
          ? true
          : false
        : false,
      image: this.state.data
        ? this.state.data.Banner.Image.data
          ? this.state.data.Banner.Image.data.attributes.url
          : ""
        : "",
    };
    function Card(cardData) {
      return (
        <NavLink to={cardData.Link}>
          <div className="service-link-card">
            <img src={linkAPI + cardData.Image.data.attributes.url} />
            <h2>{cardData.Title}</h2>
          </div>
        </NavLink>
      );
    }
    const Cards = this.state.data
      ? this.state.data.Services_Cards.map((item) => (
          <Card key={item.id} {...item}></Card>
        ))
      : "";

    return (
      <main className="page-body">
        <Banner {...bannerProps} />
        <div className="service-type container">
          <div className="service-link-cards service-type-cards">{Cards}</div>

          <div className="service-type__service">
            <ReactMarkdown>
              {this.state.data ? this.state.data.Main_Text : ""}
            </ReactMarkdown>
          </div>
          <a
            className="banner-button service-button"
            href={this.state.data ? this.state.data.Button_Link : ""}
          >
            {this.state.data ? this.state.data.Button : ""}
          </a>
        </div>
      </main>
    );
  }
}

export default ProfessionalWriting;
