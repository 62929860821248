import React, { useEffect, useState } from "react";
import ContactForm from "./contactForm";
import "../../scss/pages/_contact.scss";
import "normalize.css";
import Banner from "../banner";
import BannerImage from "../../imgs/reach_out.svg";
import { NavLink } from "react-router-dom";
import VidyardEmbed from "@vidyard/embed-code";
import axios from "axios";
import linkAPI from "../../hooks/linkAPI";
import ReactMarkdown from "react-markdown";

export default function Contact(props) {
  const [data, setData] = useState(null);
  useEffect(() => {
    VidyardEmbed.api.renderDOMPlayers();
    const getData = async () => {
      const res = await axios.get(linkAPI + "/api/reach-out?populate=deep");
      setData(res.data.data.attributes);
    };
    getData();
  }, []);

  const bannerProps = {
    title: data ? data.Banner.Title : "",
    title2: data ? (data.Banner.Title2 ? data.Banner.Title2 : "") : "",
    body: data ? data.Banner.Description : "",
    buttonCopy: data
      ? data.Banner.Above_Button
        ? data.Banner.Above_Button
        : null
      : "",
    action: data ? (data.Banner.Button ? data.Banner.Button : "") : "",
    link: data ? (data.Banner.Button_Link ? data.Banner.Button_Link : "") : "",
    video: data ? (data.Banner.Video ? true : false) : false,
    videoLink: data ? (data.Banner.Video ? data.Banner.Video : "") : "",
    videoID: data ? (data.Banner.VideoID ? data.Banner.VideoID : "") : "",
    image: data ? (data.Banner.Image.data ? true : false) : false,
    image: data
      ? data.Banner.Image.data
        ? data.Banner.Image.data.attributes.url
        : ""
      : "",
  };
  return (
    <div className="contact">
      <Banner {...bannerProps} />
      <div className="contact-row-fluid">
        <div className="contact-wrapper">
          <div className="contact-section-items">
            <div className="contact-container">
              <div className="contact-section-row">
                <div className="contact-section-column">
                  <div className="contact-section">
                    <div className="contact-section-form">
                      <ContactForm />
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact-section-row">
                <div className="contact-section-column">
                  <div className="contact-section-content">
                    <ReactMarkdown>
                      {data ? data.Bellow_Form : ""}
                    </ReactMarkdown>
                    <div className="button-container">
                      <NavLink
                        className="banner-button"
                        to="/contact/professional"
                      >
                        Join Our Team
                      </NavLink>
                    </div>

                    <div className="contact-section-text">
                      <p className="confidentiality">
                        {data ? data.Bellow_Button : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
