import React from "react";
import Icon from "../svg/home/svgLogo";
import { useMediaPredicate } from "react-media-hook";
import linkAPI from "../hooks/linkAPI";

function Card(props) {
  return (
      <a href={props.item.Link} style={{all: "unset"}}>
    <div className={`card ${props.noButton ? "no-button" : ""}`} id={props.item.id}>
      <div className="card__content">
        <div className={`card__image`}>
          <img className={`${props.large ? "lg" : ""}`} src={linkAPI + props.item.Image.data.attributes.url} alt={props.item.Title} />
        </div>
        <div className="card__body">
          <h2>{props.item.Title}</h2>
          <p>
            {props.item.Description}
          </p>
        </div>
      </div>

      {/* {props.item.buttonText && props.item.link && (
        <a className="card-button" href={props.item.link}>
          {props.item.buttonText}
        </a>
      )} */}

      {/* {props.item.comingSoon && (
        <a className="card-button">
          Coming Soon
        </a>
      )} */}
    </div>
      </a>
  );
}

export default Card;
