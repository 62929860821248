import React from "react";

function CardSection(props) {

  return (
    <div className="service-cards-container">
      {props.children && (
        props.children
      )}
    </div>
  );
}
export default CardSection;
