import React from "react";
import { Typography } from "@material-ui/core";

// function for determining if project status allows for project to be modified
export const getProjectEditable = (status) => {
  return status === "unsubmitted" || status === "awaiting deposit";
};

// function for determining if project scope is filled out (any stage past unsubmitted)
export const getProjectHasData = (project) => {
  return project.status !== "unsubmitted" && project.scope !== undefined;
};

// function for calculating flat admin/creative connex fee
export const calculateAdminFee = (totalCents, percentage, minimumCents) => {
  if (totalCents === 0) return 0;

  // first get percentage of total
  let finalAmount = totalCents * (percentage / 100);

  // then enforce minimum
  if (finalAmount < minimumCents) finalAmount = minimumCents;

  return finalAmount;
};

// function for adding percentage of tax to number
export const addTax = (amount, percentage) => {
  return amount + amount * (percentage / 100);
};

// function for calculating total deposit amount
export const calculateDepositTotal = (
  servicesTotal,
  adminFee,
  depositPercentage,
  taxPercentage
) => {
  if (servicesTotal === 0) {
    return 0;
  }
  return addTax(
    servicesTotal * (depositPercentage / 100) + adminFee,
    taxPercentage
  );
};

export const calculateServiceTotal = (product, quantity) => {
  const total = product.price * quantity;

  if (!!product.metadata.minimum) {
    return total < product.metadata.minimum ? product.metadata.minimum : total;
  }
  return total;
};

export const createProductLabel = (name) => {
  const hasBrackets = name.includes("(");

  if (hasBrackets) {
    // create line break in product with brackets
    const substrings = name.split("(");
    return (
      <>
        {substrings[0]}
        <br />
        {`(${substrings[1]}`}
      </>
    );
  } else {
    // otherwise just return name
    return <Typography>{name}</Typography>;
  }
};
