import React, { useState } from "react";
import {
  ThemeProvider,
  makeStyles,
  createMuiTheme,
} from "@material-ui/core/styles";
import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import amber from "@material-ui/core/colors/amber";
import axios from "axios";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { apiUrl } from "../../utils/url";

const theme = createMuiTheme({
  palette: {
    primary: amber,
    secondary: {
      main: "#3F3D56",
    },
  },
  typography: {
    fontFamily: "'Work Sans', sans-serif",
    htmlFontSize: 10,
  },
  overrides: {
    MuiFilledInput: {
      input: {
        padding: "10px 10px 10px",
      },
      root: {
        padding: "0",
      },
    },
    MuiAutocomplete: {
      input: {
        padding: "10px 10px 10px",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  multiline: {
    width: "100%",
    margin: "0 auto",
    marginBottom: "3rem",
  },
  button: {
    color: "#fff",
    fontSize: "1.5rem",
    padding: "0.5rem 0",
    marginTop: "0.3rem",
    borderRadius: "6rem",
    width: "120px",
  },
  textField: {
    height: "6rem",
  },
}));

// constants for form options
const HAS_EXPERIENCE_OPTIONS = ["No", "Yes"];

const FREELANCING_OPTIONS = ["No", "Yes"];

const CONTACTED_BY_OPTIONS = ["Email", "Phone", "No Preference"];

const SERVICES_OPTIONS = [
  "Writing Coaching & Consulting",
  "Manuscript Evaluation",
  "Editing - Copy",
  "Editing - Content",
  "Editing - Developmental",
  "Editing - Proofreading",
  "Book / Page Layout Design",
  "Custom Illustration",
  "Custom Cover Design (Front & Back)",
  "Translation",
  "Custom Logo Design",
];

const GENRES_OPTIONS = [
  "Children & Young Adult",
  "Adult Fiction & Non-Fiction",
  "Short Story",
  "Family History, Legacy, Biography, Memoir",
  "Autobiography",
  "Business",
  "Self Help",
  "Workbook",
  "Religion & Spirituality",
  "Special Interest i.e. recipe/cookbook, food, wine, poetry, sports",
];

const FOUND_BY_OPTIONS = [
  "Google Search",
  "Social / Digital Media Posting",
  "Social / Digital Media Ad",
  "Word-of-Mouth",
  "A Creative Connex Client",
  "A Creative Connex Professional",
];

const VALIDATION_SCHEMA = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .max(50, "Max 50 characters.")
    .required("Required."),
  lastName: Yup.string()
    .trim()
    .max(50, "Max 50 characters.")
    .required("Required."),
  city: Yup.string().trim().max(50, "Max 50 characters.").required("Required."),
  region: Yup.string().trim().max(50, "Max 50 characters."),
  country: Yup.string()
    .trim()
    .max(50, "Max 50 characters.")
    .required("Required."),
  email: Yup.string().email("Must be a valid email.").required("Required."),
  phone: Yup.string()
    .required("Phone Required")
    .trim()
    .min(4, "Minimum 4 characters.")
    .max(20, "Max 20 characters"),
  foundBy: Yup.string().trim().max(50, "Max 50 characters."),
  accreditations: Yup.array()
    .of(Yup.string().max(100, "Max 100 characters for one accreditation."))
    .max(5, "Maximum 5 accreditations."),
  genres: Yup.array()
    .of(Yup.string().max(100, "Max 100 characters for one accreditation."))
    .min(1, "Required."),
  services: Yup.array().min(1, "Required."),
});
const INITIAL_VALUES = {
  firstName: "",
  lastName: "",
  city: "",
  region: "",
  country: "",
  email: "",
  phone: "",
  foundBy: "",
  accreditations: [],
  genres: [],
  services: [],
};

export default function ContactForm() {
  const classes = useStyles();
  const [hasExperience, setHasExperience] = useState(HAS_EXPERIENCE_OPTIONS[0]);
  const [freelancing, setFreelancing] = useState(FREELANCING_OPTIONS[0]);
  const [contactedBy, setContactedBy] = useState(CONTACTED_BY_OPTIONS[0]);
  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  // change handler for select
  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    if (name === "hasExperience") setHasExperience(value);
    else if (name === "freelancing") setFreelancing(value);
    else if (name === "contactedBy") setContactedBy(value);
  };

  // function for submitting lead
  const handleSubmitProfessionalInterest = async (values) => {
    const url = `${apiUrl}/leads/professional`;
    const payload = values;
    const response = await axios.post(url, payload);
    return response.data;
  };

  return (
    <Formik
      onSubmit={async (values, actions) => {
        try {
          const valuesForAPI = {
            ...values,
            hasExperience,
            freelancing,
            contactedBy,
            name: `${values.firstName} ${values.lastName}`,
          };
          await handleSubmitProfessionalInterest(valuesForAPI);
          actions.resetForm();
          setHasExperience(HAS_EXPERIENCE_OPTIONS[0]);
          setFreelancing(FREELANCING_OPTIONS[0]);
          setContactedBy(CONTACTED_BY_OPTIONS[0]);
          handleOpenDialog();
        } catch (e) {}
      }}
      validationSchema={VALIDATION_SCHEMA}
      initialValues={INITIAL_VALUES}
    >
      {({ values, errors, handleChange, setFieldValue }) => (
        <Form className={classes.root}>
          <ThemeProvider theme={theme}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                {/* First name field */}
                <TextField
                  className={classes.textField}
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  label="First Name*"
                  variant="outlined"
                  color="secondary"
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                {/* Last name field */}
                <TextField
                  className={classes.textField}
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  label="Last Name*"
                  variant="outlined"
                  color="secondary"
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                {/* Email name field */}
                <TextField
                  className={classes.textField}
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  label="Email*"
                  variant="outlined"
                  color="secondary"
                  error={!!errors.email}
                  helperText={errors.email}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                {/* Phone field */}
                <TextField
                  className={classes.textField}
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  label="Phone Number*"
                  variant="outlined"
                  color="secondary"
                  error={!!errors.phone}
                  helperText={errors.phone}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                {/* City field */}
                <TextField
                  className={classes.textField}
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  label="City*"
                  variant="outlined"
                  color="secondary"
                  error={!!errors.city}
                  helperText={errors.city}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                {/* Region field */}
                <TextField
                  className={classes.textField}
                  name="region"
                  value={values.region}
                  onChange={handleChange}
                  label="Province/State/Region"
                  variant="outlined"
                  color="secondary"
                  error={!!errors.region}
                  helperText={errors.region}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                {/* Country field */}
                <TextField
                  className={classes.textField}
                  name="country"
                  value={values.country}
                  onChange={handleChange}
                  label="Country*"
                  variant="outlined"
                  color="secondary"
                  error={!!errors.country}
                  helperText={errors.country}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Autocomplete
                  freeSolo
                  id="foundBy"
                  inputValue={values.foundBy}
                  value={values.foundBy}
                  onInputChange={(_e, value) => {
                    setFieldValue("foundBy", value);
                  }}
                  options={FOUND_BY_OPTIONS.map((option) => option)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.question_text}
                      variant="outlined"
                      error={Boolean(errors.foundBy)}
                      helperText={errors.foundBy}
                      fullWidth
                      label="How did you hear about Creative Connex?"
                      multiline
                      rows={2}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <h1 className="contact-form__heading">
                  Do you have prior experience with book development in the
                  publishing industry?
                </h1>
                <FormControl style={{ width: "100%" }}>
                  <Select
                    id="hasExperience"
                    name="hasExperience"
                    className={classes.select}
                    value={hasExperience}
                    onChange={handleSelectChange}
                    variant="filled"
                    fullWidth
                  >
                    {HAS_EXPERIENCE_OPTIONS.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                    ;
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <h1 className="contact-form__heading">
                  Are you currently freelancing?
                </h1>
                <FormControl style={{ width: "100%" }}>
                  <Select
                    id="freelancing"
                    name="freelancing"
                    className={classes.select}
                    value={freelancing}
                    onChange={handleSelectChange}
                    variant="filled"
                    fullWidth
                  >
                    {FREELANCING_OPTIONS.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                    ;
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <h1 className="contact-form__heading">
                  How would you prefer to be contacted?
                </h1>
                <FormControl style={{ width: "100%" }}>
                  <Select
                    id="contactedBy"
                    name="contactedBy"
                    className={classes.select}
                    value={contactedBy}
                    onChange={handleSelectChange}
                    variant="filled"
                    fullWidth
                  >
                    {CONTACTED_BY_OPTIONS.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                    ;
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <h1 className="contact-form__heading">
                  What creative service(s) are you able to provide?* <br />
                  <span style={{ fontStyle: "italic", fontSize: "80%" }}>
                    (Select all that apply)
                  </span>
                </h1>
                <Autocomplete
                  id="services"
                  onChange={(_e, value) => setFieldValue("services", value)}
                  value={values.services}
                  multiple
                  options={SERVICES_OPTIONS}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Service"
                      error={Boolean(errors.services)}
                      helperText={errors.services}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <h1 className="contact-form__heading">
                  What is the genre of books would you provide your service(s)
                  to?*
                  <br />
                  <span style={{ fontStyle: "italic", fontSize: "80%" }}>
                    (Select all that apply or enter your own)
                  </span>
                </h1>
                <Autocomplete
                  id="genres"
                  onChange={(_e, value) => setFieldValue("genres", value)}
                  value={values.genres}
                  multiple
                  options={GENRES_OPTIONS}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Genre"
                      error={Boolean(errors.genres)}
                      helperText={errors.genres}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <h1 className="contact-form__heading">
                  Please list any professional accreditations that you have:
                  <br />
                  <span style={{ fontStyle: "italic", fontSize: "80%" }}>
                    (Enter up to 5. Education, memberships, affiliations,
                    awards, etc.)
                  </span>
                </h1>
                <Autocomplete
                  ChipProps={{ style: { maxWidth: "40rem" } }}
                  onChange={(_e, value) =>
                    setFieldValue("accreditations", value)
                  }
                  value={values.accreditations}
                  id="accreditations"
                  options={[]}
                  freeSolo
                  multiple
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Accreditations"
                      placeholder="Accreditation"
                      error={Boolean(errors.accreditations)}
                      helperText={errors.accreditations}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} container justify="center">
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disableElevation
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
            <Dialog
              open={open}
              onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Form Submitted</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Your info has been sent! Keep an eye on your email for a
                  response.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </ThemeProvider>
        </Form>
      )}
    </Formik>
  );
}
