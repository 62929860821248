import React, { Component } from "react";
import Banner from "../banner";
import bookImage1 from "../../imgs/books/book-cover_1.jpg";
import bookImage2 from "../../imgs/books/book-cover_2.jpg";
//import bookImage3 from "../../imgs/svgs/about-us.svg";
import BannerImage from "../../imgs/bookstore.svg";

class Bookstore extends Component {
  state = {};
  render() {
    const props = {
      title:
        "Creative Connex Bookstore",
      body:
        "Support aspiring, self-published authors!",
      action: "Shop Now",
      link: "https://fire-starters.square.site/",
      image: BannerImage,
      newTab: true,
    };

    return (
      <div className="bookstore-wrapper">
        <Banner {...props} />
        <div className="bookstore container">
          <h1>Recent Releases</h1>
          <p>By making a book purchase from our bookstore you are directly supporting our clients as well as other self-published authors!</p>
          {/* <div className="bookstore__images">
            <img src={bookImage1} alt="" />
            <img src={bookImage2} alt="" />
          </div> */}

          <div className="bookstore__cta">
            <a className="banner-button" href="https://fire-starters.square.site/" target="_blank" rel="noopener">Shop Now</a>
          </div>

        </div>
      </div>
    );
  }
}

export default Bookstore;
