import React, { Component } from "react";
import Banner from "../banner";
import BannerImage from "../../imgs/promotions.svg";

class Promotions extends Component {
    state = {};

    render() {
        const bannerProps = {
            title: "Book development promotions that'll get your creative juices flowing!",
            body: "Our team is ready to work with you so here's a little extra incentive to get you on board!  But hurry and take advantage, the promotions are only for a limited time.",
            action: "Sign Up",
        };


        return (
            <main className="page-body">
                <Banner {...bannerProps} image={BannerImage} />
                <h1 className="coming-soon-heading container">
                    Keep you eyes open and get your pen ready!  In June we're launching something very exciting
                    that we know you'll love.  Shhhhhhh, that's all we can say for now so check back soon!
                </h1>
            </main>
        );
    }
}

export default Promotions;
