import React, {useEffect, useState} from "react";
import axios from "axios";
import linkAPI from "../../hooks/linkAPI";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReactMarkdown from 'react-markdown';
import TeamCards from './teamcards';

function Cards({size}) {
    const [data, setData] = useState(null);
    useEffect(() => {
        const getData = async () => {
          const res = await axios.get(linkAPI + "/api/about?populate=deep")
          setData(res.data.data.attributes.Team)
        }
        getData()
      }, []);

    function Card(cardData){ return(
        <div className="team-member">
          <img src={linkAPI + cardData.Image.data.attributes.url} alt="" className="large-picture" />
          <h2 className="team-member__name">{cardData.Name}</h2>
          <h3 className="team-member__title">{cardData.Position}</h3>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <h3 className="team-member__read-more">Read More</h3>
            </AccordionSummary>
            <AccordionDetails>
              <p className="team-member__info">
                {cardData.Description}
              </p>
            </AccordionDetails>
          </Accordion>
        </div>)
    }
    const Team = data ? data.map((item) => <Card key={item.id} {...item} ></Card>) : ""

    return !data ? (<div></div>) : size == "desktop" ? (
        <div className="connect__team">
        {Team}
        </div>
    ) 
    : (
        <div className="connect__team">
        {Team}
        </div>
    )
}

export default Cards;