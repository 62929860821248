import React, { Component } from "react";
import ReactPlayer from "react-player/youtube";
import linkAPI from "../hooks/linkAPI";
import VidyardPlayer from "react-player/vidyard";
import VidyardEmbed from "@vidyard/embed-code";

//import Icon from "../svg/home/svgLogo";
import LazyLoad from "react-lazy-load";
import { NavLink } from "react-router-dom";
import { platformUrl } from "../utils/url";

class Banner extends Component {
  constructor(props) {
    super();
  }
  componentDidMount() {
    VidyardEmbed.api.renderDOMPlayers();
  }
  static defaultProps = {
    title: "There are a million stories waiting to be told …",
    body: "Creative Connex offers complete writing, editing, and self-publishing solutions. We demystify the process. We make book writing and publishing straightforward, attainable, and affordable.",
    body2: false,
    action: "Create A Book",
    link: `${platformUrl}`,
    external: false,
    buttonCopy: false,
  };
  state = {};

  render() {
    return (
      <div
        className={`banner ${this.props.alt ? "alt" : ""} ${
          this.props.video ||
          this.props.servicesVideo ||
          this.props.contactVideo
            ? "wide"
            : ""
        }
        }`}
      >
        <div
          className={`banner-content container ${
            this.props.video ||
            this.props.servicesVideo ||
            this.props.contactVideo
              ? "wide"
              : ""
          }`}
        >
          <div className="banner-content__left">
            <h1>
              {this.props.title}
              {this.props.title2 && (
                <>
                  <br />
                  {this.props.title2}
                </>
              )}
            </h1>
            <p
              className="image-banner-caption-body"
              style={{ marginBottom: this.props.body2 ? "1rem" : undefined }}
            >
              {this.props.body}
            </p>
            {this.props.body2 && (
              <p className="image-banner-caption-body">{this.props.body2}</p>
            )}
            {this.props.buttonCopy && (
              <p className="banner-button-copy">{this.props.buttonCopy}</p>
            )}
            {!this.props.action == "" && (
              <div>
                {this.props.link.substring(0, 4) == "http" ? (
                  <a
                    href={this.props.link}
                    className="banner-button"
                    // target="_blank"
                  >
                    {this.props.action}
                  </a>
                ) : (
                  <NavLink
                    to={this.props.link}
                    className="banner-button"
                    target={this.props.newTab ? "_blank" : ""}
                  >
                    {this.props.action}
                  </NavLink>
                )}
              </div>
            )}
          </div>
          <div className="banner-content__right">
            {this.props.image && (
              <img
                className="banner-image"
                // src={this.props.image}
                src={linkAPI + this.props.image}
                alt=""
                draggable="false"
              />
            )}
            {this.props.video && (
              // <img
              //   className="vidyard-player-embed"
              //   src={this.props.videoLink}
              //   data-uuid={this.props.videoID}
              //   data-v="4"
              //   data-type="inline"
              // />
              <VidyardPlayer url={this.props.videoLink}></VidyardPlayer>
            )}
            {/* {this.props.contactVideo && (
              <img
                className="vidyard-player-embed"
                src={
                  this.props.videoLink
                    ? this.props.videoLink
                    : "https://play.vidyard.com/ULKZHY34vmf7LYemut3UJv.jpg?play_button=1"
                       https://play.vidyard.com/tJNLhCEVQBHQf1gcL1xCZe.jpg?play_button=1
                }
                data-uuid="ULKZHY34vmf7LYemut3UJv"
                data-v="4"
                data-type="inline"
              />
            )}
            {this.props.servicesVideo && (
              <img
                className="vidyard-player-embed"
                src={
                  this.props.videoLink
                    ? this.props.videoLink
                    : "https://play.vidyard.com/orYBcFT6YJqBmwPJwgrmMr.jpg?play_button=1"
                }
                data-uuid="orYBcFT6YJqBmwPJwgrmMr"
                data-v="4"
                data-type="inline"
              />
            )} */}
          </div>
        </div>
      </div>
    );
  }
}

// Old video player here - not present on website for the time being

// <div className="image-banner-inner-right">
//   <LazyLoad>
//     <ReactPlayer
//       className="react-player"
//       width="100%"
//       height="100%"
//       url="https://www.youtube.com/watch?v=Gah748TTkCU"
//       title="Creative Connex Youtube"
//     />
//   </LazyLoad>
// </div>

export default Banner;
