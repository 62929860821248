import React, { Component, useState, useEffect } from "react";
import Card from "../../components/card";
import CardSection from "../../components/cardSection";
import image1 from "../../imgs/svgs/1-on-1.svg";
import image2 from "../../imgs/creative_team.svg";
import image3 from "../../imgs/connex_academy.svg";
import image4 from "../../imgs/bookstore.svg";
import axios from 'axios';
import linkAPI from '../../hooks/linkAPI'

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {data:[]};
  }
  componentDidMount() {
    axios.get(linkAPI + "/api/homepage?populate=deep").then(res => res.data.data.attributes.Services)
    .then(res => this.setState({data:res}))
  }
  render() {
    const services = this.state.data ?
    this.state.data.map((item) => <Card key={item.id} item={item} noButton large />) :
    ""
    return (
      <section className="service-cards">
        <h1>Our Professional Services</h1>
        <CardSection>
          {services}
        </CardSection>
      </section>
    );
  }
}

export default Services;
