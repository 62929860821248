import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
  RadioGroup,
  Radio,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Tooltip,
  Checkbox,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { Component, useState, useEffect } from "react";
import Banner from "../banner";
import BannerImage from "../../imgs/services.svg";
import axios from "axios";
import { apiUrl } from "../../utils/url";
import BookItemSelection from "../../components/estimator/BookItemSelection";
import BookPriceSummary from "../../components/estimator/BookPriceSummary";
import childrensBookImage from "../../imgs/books/childrens_book.svg";
import standardBookImage from "../../imgs/books/you_write.svg";
import interviewBookImage from "../../imgs/books/we_write.svg";
import { NavLink } from "react-router-dom";

import linkAPI from "../../hooks/linkAPI";

const BOOK_TYPES = ["standard", "childrens", "interview"];

const WORD_COUNT_OPTIONS = {
  childrens: [""],
  standard: ["20000", "30000", "40000", "50000", "60000"],
  interview: ["20000", "30000", "40000", "50000", "60000"],
};

const PAGE_COUNT_OPTIONS = {
  childrens: ["16", "20", "24", "28", "32"],
  standard: [""],
  interview: [""],
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#F49D39",
    },
  },
  typography: {
    fontFamily: "'Work Sans', sans-serif",
    htmlFontSize: 10,
  },
});

const DEFAULT_WORDS = 0;
const DEFAULT_PAGES = 0;
const AVG_WORDS_PER_PAGE = 300;

const Estimator = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    const getData = async () => {
      const res = await axios.get(
        linkAPI + "/api/book-estimator?populate=deep"
      );
      setData(res.data.data.attributes);
    };
    getData();
  }, []);
  const bannerProps = {
    title: data ? data.Banner.Title : "",
    title2: data ? (data.Banner.Title2 ? data.Banner.Title2 : "") : "",
    body: data ? data.Banner.Description : "",
    buttonCopy: data
      ? data.Banner.Above_Button
        ? data.Banner.Above_Button
        : null
      : "",
    action: data ? data.Banner.Button : "",
    link: data ? data.Banner.Button_Link : "",
    video: data ? (data.Banner.Video ? true : false) : false,
    videoLink: data ? (data.Banner.Video ? data.Banner.Video : "") : "",
    videoID: data ? (data.Banner.VideoID ? data.Banner.VideoID : "") : "",
    image: data ? (data.Banner.Image.data ? true : false) : false,
    image: data
      ? data.Banner.Image.data
        ? data.Banner.Image.data.attributes.url
        : ""
      : "",
  };

  const classes = useStyles();

  // define book types
  const PROJECT_TYPES = {
    childrens: {
      id: "childrens",
      label: "Children's Book",
      subHeading: "You write",
      image: childrensBookImage,
      tooltip: "A book targeted towards children",
    },
    standard: {
      id: "standard",
      label: (
        <Typography className={classes.label}>
          Fiction /<br />
          Non-Fiction Book
        </Typography>
      ),
      subHeading: "You write",
      image: standardBookImage,
      tooltip: "A standard book of any genre (you write)",
    },
    interview: {
      id: "interview",
      label: "Non-Fiction Book",
      subHeading: "We write",

      image: interviewBookImage,
      tooltip: "A guided process to develop your book (we write)",
    },
  };

  // state
  const [selectedType, setSelectedType] = useState(BOOK_TYPES[0]);
  const [products, setProducts] = useState();
  const [standardProducts, setStandardProducts] = useState();
  const [childrensProducts, setChildrensProducts] = useState();
  const [interviewProducts, setInterviewProducts] = useState();
  const [percentages, setPercentages] = useState();
  const [words, setWords] = useState(DEFAULT_WORDS);
  const [pages, setPages] = useState(DEFAULT_PAGES);
  // setup state
  const [customChecked, setCustomChecked] = useState(
    selectedType === "childrens"
  );

  useEffect(() => {
    // fetch all products
    axios.get(`${apiUrl}/products?type=standard`).then((response) => {
      setStandardProducts(response.data);
      setProducts(response.data);
    });

    axios.get(`${apiUrl}/products?type=childrens`).then((response) => {
      setChildrensProducts(response.data);
    });

    axios.get(`${apiUrl}/products?type=interview`).then((response) => {
      setInterviewProducts(response.data);
    });

    axios.get(`${apiUrl}/products/percentages`).then((response) => {
      setPercentages(response.data);
    });
  }, []);

  // function for getting recommended page count for book
  const getRecommendedPageCount = (wordCount) => {
    return Math.ceil(wordCount / AVG_WORDS_PER_PAGE);
  };

  // change handler for book type radio button
  const handleSelectedTypeChange = (event) => {
    setSelectedType(event.target.value);
    setSelectedProducts(event.target.value);
  };

  const handleWordsChange = (event) => {
    setWords(event.target.value);
  };

  const handlePagesChange = (event) => {
    setPages(event.target.value);
  };

  const setSelectedProducts = (type) => {
    if (type === "childrens") {
      setProducts(childrensProducts);
    } else if (type === "interview") {
      setProducts(interviewProducts);
    } else {
      setProducts(standardProducts);
    }
  };

  // generate radio buttons for each book type
  // create radio buttons dynamically based off project types
  let projectTypeRadioButtons = [];
  for (const property in PROJECT_TYPES) {
    projectTypeRadioButtons.push(
      <Tooltip
        title={PROJECT_TYPES[property].tooltip}
        key={PROJECT_TYPES[property].id}
      >
        <Grid
          item
          xs={10}
          sm={4}
          container
          justify="center"
          alignItems="center"
          direction="column"
        >
          <img
            className={classes.book_svg}
            src={PROJECT_TYPES[property].image}
            alt=""
            draggable="false"
            onClick={() => {
              setSelectedProducts(property);
              setSelectedType(property);
            }}
          />
          <FormControlLabel
            value={PROJECT_TYPES[property].id}
            control={<Radio />}
            label={
              <Typography className={classes.label}>
                {PROJECT_TYPES[property].label}
              </Typography>
            }
          />
          <Typography className={classes.subHeading}>
            {PROJECT_TYPES[property].subHeading}
          </Typography>
        </Grid>
      </Tooltip>
    );
  }

  return (
    <>
      <Banner {...bannerProps} />
      <div className="estimator-container">
        <ThemeProvider theme={theme}>
          <h1>
            Try our book cost estimator...
            <br />
            it’s easy as 1, 2,3!
          </h1>
          <h2>
            Simply:
            <br />
            <br />
            <span>1. Pick 1 of the 3 book genres</span>
            <br />
            <span>2. Tell us approx. how many words &amp; pages</span>
            <br />
            <span>3. Choose the services you believe you may require</span>
            <br />
            <br />
            Your initial estimate will automatically be calculated. Go ahead,
            play around with it. You’ll quickly see just how affordable bringing
            your book to life can be!
          </h2>

          <RadioGroup
            className={classes.project_types_container}
            aria-label="Book Development Stage"
            name="stage"
            value={selectedType}
            onChange={handleSelectedTypeChange}
          >
            <Grid
              className={classes.project_types}
              container
              justify="space-evenly"
              alignItems="center"
            >
              {projectTypeRadioButtons}
            </Grid>
          </RadioGroup>

          <Grid
            container
            justify="center"
            direction="column"
            alignItems="center"
            spacing={3}
          >
            {/* Word Count Field */}
            <Autocomplete
              className="number-input"
              style={{ marginBottom: "2rem" }}
              freeSolo
              id="wordCount"
              inputValue={words.toString()}
              value={words.toString()}
              onInputChange={(_e, value) => {
                setWords(parseInt(value));

                // logic for automatically updating pagecount if custom value not selected
                if (!customChecked) {
                  setPages(getRecommendedPageCount(parseInt(value)));
                }
              }}
              options={
                WORD_COUNT_OPTIONS[selectedType].length > 1
                  ? WORD_COUNT_OPTIONS[selectedType].map((option) => option)
                  : []
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Word Count"
                  variant="filled"
                  type="number"
                  fullWidth
                />
              )}
            />

            {/* Page Count Field */}
            <Autocomplete
              className="number-input"
              freeSolo
              id="pageCount"
              inputValue={pages.toString()}
              value={pages.toString()}
              disabled={
                !customChecked &&
                (selectedType === "standard" || selectedType === "interview")
              }
              onInputChange={(_e, value) => {
                setPages(parseInt(value));
              }}
              options={
                PAGE_COUNT_OPTIONS[selectedType].length > 1
                  ? PAGE_COUNT_OPTIONS[selectedType].map((option) => option)
                  : []
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Page Count"
                  variant="filled"
                  type="number"
                  fullWidth
                />
              )}
            />
            {/* Conditional checkbox for standard and interview books */}
            {(selectedType === "standard" || selectedType === "interview") && (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={customChecked}
                      onChange={(event) => {
                        // when checked, change page count to recommended value if checking true
                        const { checked } = event.target;
                        setCustomChecked(checked);
                        if (!checked) {
                          setPages(getRecommendedPageCount(words));
                        }
                      }}
                      name="auto"
                      color="primary"
                    />
                  }
                  label="Custom"
                />
                <p className="page-count-text">
                  300 words per page is the average for most books.
                </p>
              </>
            )}
          </Grid>

          <div className="calculator-container">
            <BookItemSelection products={products} setProducts={setProducts} />
            <BookPriceSummary
              products={products}
              pricePercentages={percentages}
              wordCount={words}
              pageCount={pages}
              projectType={selectedType}
            />
          </div>
        </ThemeProvider>
        <div className="btn-estimator">
          <NavLink to={"/contact"} className="banner-button">
            Let's Chat
          </NavLink>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles({
  textfield: {
    margin: "0 4rem",
    marginBottom: "2rem",
    maxWidth: "15rem",
  },
  project_types_container: {
    marginBottom: "5rem",
  },
  project_types: {
    "@media (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  label: {
    fontWeight: 700,
    textAlign: "center",
  },
  subHeading: {
    fontWeight: 700,
    color: "#F49D39",
    fontStyle: "italic",
  },
  book_svg: {
    cursor: "pointer",
    height: "100%",
    margin: "1rem",
  },
});

export default Estimator;
