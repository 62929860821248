import React, { Component } from "react";
import Banner from "../banner";
import Card from "../../components/card";
import image1 from "../../imgs/svgs/play-button.svg";
import image2 from "../../imgs/svgs/pencil.svg";
import image3 from "../../imgs/svgs/checkboxes.svg";
import { NavLink } from "react-router-dom";
import BannerImage from "../../imgs/connex_academy.svg";

class Education extends Component {
  state = {};
  render() {
    const props = {
      title: "Struggling to put your ideas into words? Not sure where to start your publishing journey?",
      body: "Learn and develop from leading book industry experts and creative professionals",
      action: "Coming Soon!",
      image: BannerImage,
    };

    const data = [
      {
        id: "video-courses",
        image: image1,
        alt: "Video Courses",
        title: "Video Courses",
        intro:
          "Join us and attend one of our live or pre-recorded educational videos or sign up for our education and training video courses. Writing and publishing experts will cover a wide range of related topics.",
        comingSoon: true
      },
      {
        id: "writing-and-publishing",
        image: image2,
        alt: "Writing and Publishing Tools",
        title: "Writing and Publishing Tools",
        intro:
          "If you’re unsure where to start or what to do next, these tools can help you clear what may appear to be muddied waters. We’ve both developed and compiled some essential tools so that you’re well-equipped.",
        comingSoon: true
      },
      {
        id: "misc-resources",
        image: image3,
        alt: "Misc. Resources ",
        title: "Misc. Resources ",
        intro:
          "We’ve compiled some essential “go-to” resources to make your writing and publishing journey easier. Whether it’s insights, templates, checklists, videos, or resource lists, we’ve got you covered again!",
        comingSoon: true
      }
    ];
    const education = data.map((item) => <Card className="education__card" key={item.id} item={item} />);

    return (
      <div className="education-wrapper">
        <Banner {...props} />
        <div className="education container">
          <h1 id="education">Education and Training</h1>
          <p>Begin your writing journey on the right path and keep it there by engaging in and benefiting from our various education &amp; training programs and events. </p>
          <div className="education__cards container-lg">
            {education}
          </div>

          <div className="education__cta">
            <h2>Have a question? Need help? Then let’s chat!</h2>
            <NavLink to="/contact" className="banner-button">
              <a>Reach Out</a>
            </NavLink>
          </div>

        </div>
      </div>
    );
  }
}

export default Education;
