import React, { useEffect, useState } from "react";
import Services from "./services";
import Assitance from "./assistance";
import Connect from "./connect";
import Market from "./marketplace";
import CompanyInfo from "./companyInfo";
import GetStarted from "./getStarted";
import Genres from "./genres";
import Testimonial from "../../components/testimonial";
import Banner from "../banner";
import BannerImage from "../../imgs/custom_design.svg";
import VidyardEmbed from "@vidyard/embed-code";
import axios from "axios";
import linkAPI from "../../hooks/linkAPI";

const Home = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    // VidyardEmbed.api.renderDOMPlayers();
    const getData = async () => {
      const res = await axios.get(linkAPI + "/api/homepage?populate=deep");
      setData(res.data.data.attributes);
    };
    getData();
  }, []);
  const testimonialProps1 = {
    name: data ? data.Quote1.Person : "",
    body: data ? data.Quote1.Text : "",
  };

  const testimonialProps2 = {
    name: data ? data.Quote2.Person : "",
    body: data ? data.Quote2.Text : "",
  };

  const bannerProps = {
    title: data ? data.Banner.Title : "",
    title2: data ? (data.Banner.Title2 ? data.Banner.Title2 : "") : "",
    body: data ? data.Banner.Description : "",
    buttonCopy: data
      ? data.Banner.Above_Button
        ? data.Banner.Above_Button
        : null
      : "",
    action: data ? data.Banner.Button : "",
    link: data ? data.Banner.Button_Link : "",
    video: data ? (data.Banner.Video ? true : false) : false,
    videoLink: data ? (data.Banner.Video ? data.Banner.Video : "") : "",
    videoID: data ? (data.Banner.VideoID ? data.Banner.VideoID : "") : "",
    image: data ? (data.Banner.Image.data ? true : false) : false,
    image: data
      ? data.Banner.Image.data
        ? data.Banner.Image.data.attributes.url
        : ""
      : "",
  };

  const bellowBannerProps = {
    bellowBannerText: data ? data.Bellow_Banner : "",
    title: data ? data.Infographic_Title : "",
    subtitle: data ? data.Infographic_Subtitle : "",
    img: data
      ? data.Main_Image.data
        ? data.Main_Image.data.attributes.url
        : null
      : null,
    mobileImg: data
      ? data.Main_Image_Mobile.data
        ? data.Main_Image_Mobile.data.attributes.url
        : null
      : null,
    imgAlt: data ? (data.Main_Image_Alt ? data.Main_Image_Alt : null) : null,
  };

  const getStartedProps = {
    title: data ? data.Bottom_Title : "",
    button: data ? data.Bottom_Button : "",
    link: data ? data.Bottom_Button_Link : "",
  };
  // const genresProps = data ? data.Bellow_Services : "";

  return (
    <main className="page-body">
      <Banner {...bannerProps} />
      <CompanyInfo {...bellowBannerProps} />
      <Testimonial {...testimonialProps1} />
      <Services />
      {/* <Genres text={genresProps} /> */}
      <Testimonial {...testimonialProps2} />
      <GetStarted {...getStartedProps} />
    </main>
  );
};

export default Home;
