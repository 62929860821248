import React, { Component } from "react";
import Banner from "../banner";
import ConnectSection from "./connectSection";
import BannerImage from "../../imgs/about_us.svg";
import { platformUrl } from "../../utils/url";
import axios from "axios";
import linkAPI from "../../hooks/linkAPI";
class Connect extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = { data: null };
  }
  componentDidMount() {
    axios
      .get(linkAPI + "/api/about?populate=deep")
      .then((res) => res.data.data.attributes)
      // .then(res=>console.log(res.Banner))
      .then((res) => this.setState({ data: res }));
  }

  render() {
    const bannerProps = {
      title: this.state.data ? this.state.data.Banner.Title : "",
      title2: this.state.data
        ? this.state.data.Banner.Title2
          ? this.state.data.Banner.Title2
          : ""
        : "",
      body: this.state.data ? this.state.data.Banner.Description : "",
      buttonCopy: this.state.data
        ? this.state.data.Banner.Above_Button
          ? this.state.data.Banner.Above_Button
          : null
        : "",
      action: this.state.data ? this.state.data.Banner.Button : "",
      link: this.state.data ? this.state.data.Banner.Button_Link : "",
      video: this.state.data
        ? this.state.data.Banner.Video
          ? true
          : false
        : false,
      videoLink: this.state.data
        ? this.state.data.Banner.Video
          ? this.state.data.Banner.Video
          : ""
        : "",
      videoID: this.state.data
        ? this.state.data.Banner.VideoID
          ? this.state.data.Banner.VideoID
          : ""
        : "",
      image: this.state.data
        ? this.state.data.Banner.Image.data
          ? true
          : false
        : false,
      image: this.state.data
        ? this.state.data.Banner.Image.data
          ? this.state.data.Banner.Image.data.attributes.url
          : ""
        : "",
    };
    const connectProps = {
      bellowBanner: this.state.data ? this.state.data.Bellow_Banner : "",
      aboveTeam: this.state.data ? this.state.data.Above_Team : "",
      bellowTeam: this.state.data ? this.state.data.Bellow_Team : "",
    };
    return (
      <div className="connect-wrapper">
        <Banner {...bannerProps} />
        <ConnectSection {...connectProps} />
      </div>
    );
  }
}

export default Connect;
